<template>
    <div class="coupon_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <!-- 基础规则 -->
            <div class="MarketingCampaign-title"><span></span>权益券设置</div>
            <el-form-item label="" :class="{'bgf9': index%2}" v-for="(item, index) in list" :key="index">
                <!-- <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">权益券名称</span>
                        <span class="con">
                            <el-input placeholder="请输入" v-model="item.cp_name" style="width: 180px" @change="inputChange('权益券名称', item.cp_name)" @input="setInfo"></el-input>
                            
                        </span>
                    </div>
                </div> -->
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">权益券内容</span>
                        <span class="con">
                            <el-cascader v-model="item.prod_id" :options="foodsList" :props="{multiple: false, label:'e_show_label', value:'e_show_id', children: 'prod_children'}" clearable @change="changeFoodsList($event, index, item)" :key="'l-m'+ index"></el-cascader>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">权益券金额</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="item.cp_amt" style="width: 180px" @input="setInfo"></el-input>
                            <template v-if="item.give_num">
                                <span class="conpon_num_show">发放：{{item.give_num}}</span>
                                <span class="conpon_num_show">领取：{{item.get_num}}</span>
                                <span class="conpon_num_show">未领取：{{item.un_get_num}}</span>
                            </template>
                            
                            <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                        </span>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">使用日期</span>
                        <el-date-picker v-model="item.useDatePicker" :picker-options="pickerOptions" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setInfo"></el-date-picker>
                        <span class="text_desc tit">可用日期</span>
                        <el-select v-model="item.date_type" multiple placeholder="请选择" dataName="日期选择" @change="setInfo">
                            <el-option v-for="item in dayList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item" style="display:flex; align-items: center;">
                        <span class="text_desc tit">使用时间</span>
                        <el-radio-group v-model="item.use_time_type" style="display:flex; align-items: center;">
                            <el-radio :label="'1'">早午晚市</el-radio>
                            <el-checkbox-group v-model="item.market_type" :disabled="item.use_time_type == '2'" @change="setInfo" style="margin-right: 10px;">
                                <el-checkbox :label="child.id" v-for="child in marketList" :key="child.id">{{child.label}}</el-checkbox>
                            </el-checkbox-group>
                            <el-radio :label="'2'">时间区间
                                <el-time-picker is-range v-model="item.useTimePicker" :disabled="item.use_time_type == '1'" value-format="HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="setInfo"></el-time-picker>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">使用条件</span>
                    </div>
                    <div class="rules_item flex_1">
                        <span class="con">
                            <span class="show_text">消费金额满</span><el-input type="number" min="0" placeholder="请输入" v-model="item.condition_amt" style="width: 100px" @input="setInfo"></el-input><span class="show_text">元可用</span>
                        </span>
                        <span class="con">
                            <span class="show_text">消费人数满</span><el-input type="number" min="0" placeholder="请输入" v-model="item.condition_per_num" style="width: 100px" @input="setInfo"></el-input><span class="show_text">人可用</span>
                        </span>
                        <span class="con">
                            <span class="show_text">消费性别</span>
                            <el-select v-model="item.condition_sex" multiple placeholder="请选择" style="width: 160px;" @change="setInfo">
                                <el-option v-for="item in sexList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                            </el-select>
                        </span>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem()"> 新增权益券设置</i>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import bus from '../../../../utils/bus'
import {if_text_valid} from '../../../../api/public'
export default {
    props: {
        init_list: {type: Array, default: ()=>[]},
        dayList: {type: Array, default: ()=>[]},
        marketList: {type: Array, default: ()=>[]},
        sexList: {type: Array, default: ()=>[]},
        
    },
    data() {
        return {
            list: [],
            pickerOptions: {
                disabledDate(time) {
                return !(time.getTime() > Date.now() - 24*60*60*1000);
                }
            },
            selectedLimit: [],
            foodsList: []
        }
    },
    computed: {
        foodsShopList: function() {return this.$store.state.foodsShopList}
    },
    mounted() {
        this.initForm()
    },
    methods: {
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        onSubmit() {
            return this.list
        },
        addItem() {
            this.list.push(this.formData())
        },
        initForm() { 
            this.list = [this.formData()]
        },
        getSelectedLimit() {
            let changeList = JSON.parse(JSON.stringify(this.$store.state.foodsShopList))
            changeList.map(item=>{

                item.disabled = false
            })
            this.selectedLimit = []
            this.list.map(item=>{
                let str = item.prod_id
                this.selectedLimit.push(str)
            })
            
            this.selectedLimit.map(item=>{
                changeList.map(child=>{
                    if(child.prod_children && child.prod_children.length > 0) {
                        child.prod_children.map(son=>{
                            if(son.prod_id == item){
                                son['disabled'] = true
                            }
                        })
                    }
                    
                })
            })
            let arr = []
            changeList.map(item=>{
                let obj = JSON.parse(JSON.stringify(item))
                arr.push(obj)
            })
            this.foodsList = arr
        },
        setInfo(e) {
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                obj.date_start = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[0] : ''
                obj.date_end = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[1] : ''
                obj.time_start = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[0] : ''
                obj.time_end = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[1] : ''
                
                obj['condition_sex'] = item.condition_sex.join(',')
                obj['market_type'] = item.market_type.join(',')
                obj['date_type'] = item.date_type.join(',')
                
                // delete obj.useDatePicker
                // delete obj.useTimePicker
                list.push(obj)
            })
            this.getSelectedLimit()
            bus.$emit('couponList', this.list)
            this.$emit('getList', this.list)
            console.log('this.list', this.list)
        },
        formData() {
            return {
                cp_id: `CP${this.createParseDateTime()}`,
                cp_name: '',
                prod_id: '',
                cp_amt: '',
                use_date_type: '1',
                date_type: [],
                date_start: '',
                date_end: '',
                use_time_type: '1',
                market_type: ['1', '2', '3'],
                time_start: '',
                time_end: '',
                condition_amt: '',
                condition_per_num: '',
                condition_sex: [],
                // 提交时需要删除
                useDatePicker: [],
                useTimePicker: null
            }
        },
        changeFoodsList(e, index, prod_name) {
            console.log('e, index', this.foodsList, e, index, prod_name)
            console.log('prod_id', this.foodsList, e, index, prod_name)
            // let idArr = []
            // e.map(item=>{
            //     idArr.push(item[1])
            // })
            // console.log('idArr', idArr)
            for(let i=0; i<this.foodsList.length; i++) {
                let arr = this.foodsList[i].prod_children
                for(let j=0; j<arr.length; j++) {
                    console.log(e[1], arr[j])
                    if(arr[j] && arr[j].prod_id == e[1]) {
                        this.list[index].prod_name = arr[j].prod_name
                    }
                }
            }
            this.list[index].prod_id = e[1]
            bus.$emit('couponList', this.list)
            this.setInfo()
        },
        createParseDateTime() {
            return Date.parse(new Date())
        },
        verifyData() {
            let  __this = this
            return new Promise((resolve, reject)=>{
                let arr = __this.list
                for(let i=0; i<arr.length; i++) {
                    let item = arr[i]
                    for(let key in item) {
                        if(!item[key] && key=='date_type' && item.use_date_type=='1') {
                            resolve({isError: true, title: `日期选择不能为空`, content: `第${i+1}个权益券的日期选择不能为空`})
                            return
                        } else if(key=='useDatePicker' && !item[key].join(',') && item.use_date_type=='2') {
                            resolve({isError: true, title: `日期区间不能为空`, content: `第${i+1}个权益券的 使用日期 日期区间 不能为空`})
                            return
                        } else if(!item[key] && key=='use_time_type' && item.use_time_type=='1') {
                            resolve({isError: true, title: `早午晚市不能为空`, content: `第${i+1}个权益券的使用时间时间早午晚市不能为空`})
                            return
                        } else if(key=='useTimePicker' && item[key]==null && item.use_time_type=='2') {
                            resolve({isError: true, title: `时间区间不能为空`, content: `第${i+1}个权益券的 使用时间 时间区间 不能为空`})
                            return
                        } else{
                            if(!item[key]){
                                let keyArr = __this.formDataToLabel()
                                for(let j=0;j<keyArr.length; j++) {
                                    if(keyArr[j].key == key) {
                                        resolve({isError: true, title: `${keyArr[j].label}不能为空`, content: `第${i+1}个权益券的${keyArr[j].label}不能为空`})
                                        return
                                    }
                                }
                            }
                        } 
                    }
                }


                
            })
        },
        formDataToLabel() {
            return [
                {key: 'cp_id', label: 'ID'},
                {key: 'cp_name', label: '名称'},
                {key: 'prod_id', label: '菜品'},
                {key: 'cp_amt', label: '金额'},
                // {key: 'use_date_type', label: '使用日期类型'},
                // {key: 'date_type', label: '日期选择'},
                // {key: 'date_start', label: '开始日期'},
                // {key: 'date_end', label: '结束日期'},
                // {key: 'use_time_type', label: '使用时间类型'},
                // {key: 'market_type', label: '早午晚市选择'},
                // {key: 'time_start', label: '开始时间'},
                // {key: 'time_end', label: '结束时间'},
                {key: 'condition_amt', label: '消费满'},
                {key: 'condition_per_num', label: '人数'},
                {key: 'condition_sex', label: '性别'}
            ]
            
        },
        async inputChange(name, text) {
            let res = await if_text_valid({data: {text: text}})
            if(res.code == 1100) {
                this.$message({message: name + '包含违规内容', type: 'error'});
                return
            }
        }
    },
    watch: {
        init_list: function(v, o) {
            let arr = []
            if(v && v.length > 0) {
                console.log('vvvvvvvvvvvvv', v)
                v.map(item=>{
                    let obj = {}
                    for(let key in item) {
                        obj[key] = JSON.parse(JSON.stringify(item[key]))
                    }
                    if(item.date_start && item.date_end) obj['useDatePicker'] = [`${item.date_start}`, `${item.date_end}`]
                    else obj['useDatePicker'] = []
                    if(item.time_start && item.time_end) obj['useTimePicker'] = [`${item.time_start}`, `${item.time_end}`]
                    else obj['useTimePicker'] = null
                    if(typeof item.condition_sex == 'string') obj['condition_sex'] = item.condition_sex.split(',')
                    if(typeof item.market_type == 'string') obj['market_type'] = item.market_type.split(',')
                    if(typeof item.date_type == 'string') obj['date_type'] = item.date_type.split(',')
                    // obj['prod_id_arr'] = []
                    // let foodsList = item.prod_id.split(',')
                    // foodsList.map(str=>{
                    //     this.$store.state.foodsShopList.map(itemB=>{
                    //         itemB.prod_children.map(child=>{
                    //             if(child.prod_id == str) {
                    //                 obj.prod_id_arr.push([itemB.id, str])
                    //             }
                    //         })
                    //     })
                    // })
                    arr.push(obj)
                })
                this.list = arr
                bus.$emit('couponList', this.list)
            }
            else {
                this.initForm()
            }
            this.getSelectedLimit()
        }
    }
}
</script>
<style lang="scss" scoped>
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    .conpon_num_show{ margin-left: 15px; color: #333; }
    
}
</style>