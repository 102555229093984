
import {query_prod_tree_of_1cat_2prod} from '../api/foodqiye'
// 通过shop_id获取菜品
export function getShopFoodsList(ent_id, shop_id, __this) {
    return new Promise((resolve, reject)=>{
        let params = {data:{ent_id:ent_id,shop_id:shop_id}}
        query_prod_tree_of_1cat_2prod(params).then(res=>{
            if(res.code == 200) {
                resolve(res.body.data)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
// 通过shop_id获取菜品
export function getEntFoodsList(ent_id, __this) {
    return new Promise((resolve, reject)=>{
        let params = {data:{ent_id:ent_id}}
        query_prod_tree_of_1cat_2prod(params).then(res=>{
            if(res.code == 200) {
                resolve(res.body.data)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
