<template>
    <div class="coupon_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
         <!--
                // 
                // 拼团
                // 
            -->
            <div class="MarketingCampaign-title"><span></span>拼团</div>
            <el-form-item label="" :class="{'bgf9': index%2}" v-for="(item, index) in list" :key="'gr'+ index">
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">拼团名称</span>
                        <span class="con">
                            <el-input placeholder="请输入" v-model="item.tuan_name" style="width: 180px" @input="setInfo"></el-input>
                            <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                        </span>
                    </div>
                </div>
                <div class="rules_row col_n" style="display: flex;">
                    <div class="rules_item" style="display: flex;">
                        <span class="text_desc tit">选择菜品</span>
                        <span class="con">
                            <el-cascader v-model="item.prod_id" :options="$store.state.foodsShopList" :props="{multiple: false, label:'e_show_label', value:'e_show_id', children: 'prod_children'}" clearable @change="changeFoodsList($event, index)" :key="'l-m'+ index"></el-cascader>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">拼团价格</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="item.price_tuan" style="width: 180px" @input="setInfo"></el-input>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">拼团人数</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="item.tuan_person_num" style="width: 180px" @input="setInfo"></el-input>
                        </span>
                    </div>
                </div>
                <div class="rules_row col_n">
                    
                    <div class="rules_item" style="display: flex;">
                        <span class="text_desc tit">活动日期时间</span>
                        <span class="con">
                             <!-- <el-date-picker v-model="item.datePicker" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  @change="changeTest"></el-date-picker> -->
                            <div style="display: flex;" class="checked_date_input">
                                <div style="color: #666">日期：</div>
                                <div class="item"><el-date-picker v-model="item.date_start" :picker-options="pickerOptions" value-format="yyyy-MM-dd" type="date" placeholder="选择开始日期" @change="setInfo"></el-date-picker></div>
                                <div class="item"><el-date-picker v-model="item.date_end" :picker-options="pickerOptions" value-format="yyyy-MM-dd" type="date" placeholder="选择结束日期" @change="setInfo"></el-date-picker></div>
                            </div>
                            <div style="display: flex;" class="checked_date_input">
                                <div style="color: #666">时间：</div>
                                
                                <div class="item"><el-time-picker v-model="item.time_start" value-format="HH:mm:ss" format="HH:mm:ss" placeholder="选择开始时间" @change="setInfo"></el-time-picker></div>
                                <div class="item"><el-time-picker v-model="item.time_end" value-format="HH:mm:ss" format="HH:mm:ss" placeholder="选择结束时间" @change="setInfo"></el-time-picker></div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">使用日期</span>
                        <el-date-picker v-model="item.useDatePicker" :picker-options="pickerOptions" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setInfo"></el-date-picker>
                        <span class="text_desc tit">可用日期</span>
                        <el-select v-model="item.date_type" multiple placeholder="请选择" dataName="日期选择" @change="setInfo">
                            <el-option v-for="item in dayList" :key="'date-'+item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item" style="display:flex; align-items: center;">
                        <span class="text_desc tit">使用时间</span>
                        <el-radio-group v-model="item.use_time_type" @change="useTimeTypeChange($event, item, index)" style="display:flex; align-items: center;">
                            <el-radio :label="'1'">早午晚市</el-radio>
                            <el-checkbox-group v-model="item.market_type" :disabled="item.use_time_type == '2'" @change="setInfo" style="margin-right: 10px;">
                                <el-checkbox :label="child.id" v-for="child in marketList" :key="'daytype-'+child.id">{{child.label}}</el-checkbox>
                            </el-checkbox-group>
                            
                            <el-radio :label="'2'">时间区间
                                <el-time-picker is-range v-model="item.useTimePicker" :disabled="item.use_time_type == '1'" value-format="HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="setInfo"></el-time-picker>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">拼团详情</span>
                        <span class="con">
                            <el-input type="textarea" placeholder="请输入" v-model="item.tuan_detail" style="width: 380px" @input="setInfo"></el-input>
                        </span>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">活动规则</span>
                        <span class="con">
                            <el-input type="textarea" placeholder="请输入" v-model="item.rule_detail" style="width: 380px" @input="setInfo"></el-input>
                        </span>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem()"> 新增拼团</i>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        init_list: {type: Array, default: ()=>[]},
        shop_id: {type: String | Number, default: ()=>''},
        marketList: {type: Array, default: ()=>[]},
        dayList: {type: Array, default: ()=>[]}
    },
    data() {
        return {
            list: [],
            pickerOptions: {
                disabledDate(time) {
                return !(time.getTime() > Date.now() - 24*60*60*1000);
                }
            }
        }
    },
    mounted() {
        this.initForm()
    },
    components: {},
    methods: {
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        addItem() {
            this.list.push(this.formData())
        },
        initForm() { 
            this.list = [this.formData()]
        },
        useTimeTypeChange(v, item, index) {
            console.log('v, item, index', v, item, index)
            if(v=='1' && this.list[index].market_type.length <= 0) {
                this.list[index].market_type = ['1', '2', '3']
            }
        },
        setInfo(e) {
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                obj.use_date_start = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[0] : ''
                obj.use_date_end = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[1] : ''
                obj.use_time_start = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[0] : ''
                obj.use_time_end = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[1] : ''
                
                obj['market_type'] = item.market_type.join(',')
                obj['date_type'] = item.date_type.join(',')
                list.push(obj)
            })
            this.$emit('getList', list)
        },
        formData() {
            return {
                tuan_name: '',
                prod_id: '',
                price_tuan: '',
                tuan_person_num: '',
                date_start: '',
                date_end: '',
                time_start: '',
                time_end: '',
                datePicker: '',

                use_date_type: '1',
                date_type: [],
                use_date_start: '',
                use_date_end: '',
                use_time_type: '1',
                market_type: ['1', '2', '3'],
                use_time_start: '',
                use_time_end: '',
                useDatePicker: [],// 提交时需要删除
                useTimePicker: null,
            }
        },
        changeFoodsList(e, index) {
            // let idArr = []
            // e.map(item=>{
            //     idArr.push(item[1])
            // })
            this.list[index].prod_id = e[1]
            this.setInfo()
        },
    },
    watch: {
        init_list: function(v, o) {
            if(v && v.length > 0) {
                let arr = []
                v.map(item=>{
                    let obj = {}
                    for(let key in item) {
                        obj[key] = JSON.parse(JSON.stringify(item[key]))
                    }
                    if(item.use_date_start && item.use_date_end) obj['useDatePicker'] = [`${item.use_date_start}`, `${item.use_date_end}`]
                    else obj['useDatePicker'] = []
                    if(item.use_time_start && item.use_time_end) obj['useTimePicker'] = [`${item.use_time_start}`, `${item.use_time_end}`]
                    else obj['useTimePicker'] = null

                    if(typeof item.market_type == 'string') obj['market_type'] = item.market_type.split(',').length >= 0? item.market_type.split(','): ['1', '2', '3']
                    else obj['market_type'] = []
                    if(typeof item.date_type == 'string') obj['date_type'] = item.date_type.split(',')
                    else obj['date_type'] = []
                    arr.push(obj)
                })
                this.list = arr
            } else {
                this.initForm()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.checked_date_input{ display: flex; }
.checked_date_input .item{margin-right: 15px; margin-bottom: 10px;}
/deep/.checked_date_input .el-input__prefix{ width: 100%; right: 0; }
/deep/.checked_date_input .el-input__prefix .el-input__icon{ width: 100%; text-align: left; }
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>