<!-- // earl 2021-09-06 -->
<template>
    <div class="setting_rules">
        <div class="MarketingCampaign-title">
          <span></span>
          优惠活动设置
        </div>
        <el-form ref="form" :model="submitForm" label-width="110px" class="form_info_box">
            <el-form-item label="会员规则启用">
                <el-radio-group v-model="submitForm.flag_mc_level">
                    <el-radio :label="'1'">启用</el-radio>
                    <el-radio :label="'0'">停用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="会员规则">
                <ul class="member_rules_list">
                    <li v-for="(item, index) in submitForm.list_level" :key="index">
                        <span class="text_desc">累计</span>
                        <el-input type="number" min="0" placeholder="请输入最小数字" :disabled="index==0" v-model="item.point_start" style="width: 180px">
                            <template slot="append">分</template>
                        </el-input>
                        <span class="text_desc">——</span>
                        <el-input type="number" min="0" placeholder="请输入最大数字" v-model="item.point_end" @input="changeRulesArr(item, index)" style="width: 180px">
                            <template slot="append">分</template>
                        </el-input>
                        <span class="text_desc">成为</span>
                        <el-input placeholder="会员等级" v-model="item.level_name" style="width: 180px"></el-input>
                        <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                    </li>
                    <li class="add_new">
                        <i class="el-icon-plus add_btn" @click="addItem"> 增加方案</i>
                    </li>
                    <li class="add_new">
                        <el-select v-model="useRules" multiple placeholder="请选择" @change="changeUseRules">
                            <el-option v-for="item in submitForm.list_level" :key="item.level_id" :label="item.level_name" :value="item.level_id"></el-option>
                        </el-select>
                        <span class="text_desc">享受会员价</span>
                    </li>
                    
                </ul>
            </el-form-item>
            <el-form-item label="积分规则启用">
                <el-radio-group v-model="submitForm.flag_point">
                    <el-radio :label="'1'">启用</el-radio>
                    <el-radio :label="'0'">停用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="积分规则">
                <span class="text_desc">消费</span>
                <el-input type="number" min="0" placeholder="金额" v-model="submitForm.consume_amt" style="width: 180px">
                    <template slot="append">元</template>
                </el-input>
                <span class="text_desc">获得</span>
                <el-input type="number" min="0" placeholder="积分" v-model="submitForm.consume_point" style="width: 180px">
                    <template slot="append">积分</template>
                </el-input>
            </el-form-item>
            <el-form-item label="堂食充值规则" class="order_charge_rule">
                <el-radio-group v-model="submitForm.flag_topup_order">
                    <el-radio :label="'1'">启用</el-radio>
                    <el-radio :label="'0'">停用</el-radio>
                </el-radio-group>
                <el-row v-if="submitForm.flag_topup_order=='1'">
                    <el-col :span="3"><span>原价赠送</span><el-input type="number" placeholder="" v-model="submitForm.give_orig" style="width: 80px"></el-input><span>%</span></el-col>
                    <el-col :span="3"><span>整价赠送</span><el-input type="number" placeholder="" v-model="submitForm.give_all" style="width: 80px"></el-input><span>%</span></el-col>
                </el-row>
                <el-row style="margin-top: 10px;" v-if="submitForm.flag_topup_order=='1'">
                    <el-col :span="3"><span>二倍赠送</span><el-input type="number" placeholder="" v-model="submitForm.give_2" style="width: 80px"></el-input><span>%</span></el-col>
                    <el-col :span="3"><span>三倍赠送</span><el-input type="number" placeholder="" v-model="submitForm.give_3" style="width: 80px"></el-input><span>%</span></el-col>
                    <el-col :span="3"><span>四倍赠送</span><el-input type="number" placeholder="" v-model="submitForm.give_4" style="width: 80px"></el-input><span>%</span></el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="会员充值规则">
                <el-row>
                    <el-col :span="24">
                        <el-radio-group v-model="submitForm.flag_topup_fix">
                            <el-radio :label="'1'">启用</el-radio>
                            <el-radio :label="'0'">停用</el-radio>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="24" v-if="submitForm.flag_topup_fix=='1'">
                        <el-radio-group v-model="submitForm.give_type">
                            <el-radio :label="'1'">赠送金额</el-radio>
                            <el-radio :label="'0'">赠送百分比</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <div v-if="submitForm.flag_topup_fix=='1'">
                    <ul v-for="(item, index) in submitForm.list_topup_fix" :key="index" class="charge_rule">
                        <li><span>充值</span><el-input type="number" placeholder="" v-model="item.amt_start" style="width: 80px"></el-input></li>
                        <li><span>——</span><el-input type="number" placeholder="" v-model="item.amt_end" @input="changeChargeRulesArr(item, index)" style="width: 80px"></el-input><span>元</span></li>
                        <li><span>赠送</span><el-input type="number" placeholder="" v-model="item.give_value" style="width: 80px"></el-input><span v-if="submitForm.give_type=='1'">元</span><span v-if="submitForm.give_type=='2'">%</span></li>
                        <li><i class="el-icon-delete del_btn" @click="delChargeRuleItem(index)"> 删除</i></li>
                    </ul>
                </div>
                
                <div class="add_new charge_rule_btn">
                    <i class="el-icon-plus add_btn" @click="addChargeRuleItem"> 增加方案</i>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {insert_member_rule_ming, query_member_rule_ming} from '../../../api/store'
export default {
    data() {
        return {
            currentSubmit: {
                flag_charge: '1',
            },
            chargeRuleList: [
                {a: ''}
            ],
            submitForm: {},
            useRules: []
        }
    },
    mounted() {
        this.initForm()
    },
    methods: {
        delItem(index) {
             this.submitForm.list_level.splice(index, 1)
        },
        addItem() {
            if(this.submitForm.list_level && this.submitForm.list_level.length > 0) {
                let newStartNumber = Number(this.submitForm.list_level[this.submitForm.list_level.length - 1].point_end) + 1
                this.submitForm.list_level.push({level_id: `${this.createParseDateTime()}`, point_start: newStartNumber, point_end: '', level_name: ''})
            } else {
                this.submitForm.list_level.push({level_id: `${this.createParseDateTime()}`, point_start: '', point_end: '', level_name: ''})
            }
        },
        changeUseRules(e) {
            this.submitForm.list_level.map(item=>{
                e.map(child=>{
                    if(item.level_id==child) {
                        item.flag_member_price = '1'
                    }
                })
            })
        },
        async onSubmit() {
            let isRun = true
            this.submitForm.list_level.map((item, index)=>{
                if(!item.point_start) {
                    this.$message({message: '会员规则最小积分未填写', type: 'error'})
                    isRun = false
                } else if(!item.point_end) {
                    this.$message({message: '会员规则最大积分未填写', type: 'error'})
                    isRun = false
                } else if(!item.level_name) {
                    this.$message({message: '会员等级未填写', type: 'error'})
                    isRun = false
                } else {
                    for(let i=0; i<this.submitForm.list_level.length; i++) {
                        if(item.level_name == this.submitForm.list_level[i].level_name && i != index){
                            this.$message({message: '会员等级重复，请重新填写', type: 'error'})
                            isRun = false
                        }
                    }
                }
            })
            if(!isRun) return
            let params = {data: this.submitForm}
            let res = await insert_member_rule_ming(params), data = {}
            
            if(res && res.code == 200) {
                this.$message({message: '恭喜您，完成提交！', type: 'success'})
            } else {
                this.$message({message: res.msg, type: 'error'})
            }
        },
        // 填写规则
        changeRulesArr(item, index) {
            let list = this.submitForm.list_level
            let nextItem = this.submitForm.list_level[index+1]
            
            // 如果有下一个，则给下一个对象开始加1
            if(nextItem) {
                // 如果当前修改的结束 >= 下一个对象的结束，则剩下的input全部清空
                if(Number(item.point_end) >= Number(nextItem.point_end)) {
                    for(let i=index+1; i<list.length; i++) {
                        list[i].point_start = ''
                        list[i].point_end = ''
                    }
                }
                nextItem.point_start = Number(item.point_end) + 1
            }
        },
        addChargeRuleItem() {
            if(this.submitForm.list_topup_fix && this.submitForm.list_topup_fix.length > 0) {
                let newStartNumber = Number(this.submitForm.list_topup_fix[this.submitForm.list_topup_fix.length - 1].amt_end) + 1
                this.submitForm.list_topup_fix.push({plan_id: `Fixed${new Date().getTime()}`, give_value: '', amt_start: newStartNumber, amt_end: ''})
                console.log({plan_id: `Fixed${new Date().getTime()}`, give_value: '', amt_start: newStartNumber, amt_end: ''})
            } else {
                this.submitForm.list_topup_fix.push({plan_id: `Fixed${new Date().getTime()}`, give_value: '', amt_start: '', amt_end: ''})
            }
            
        },
        // 填写规则
        changeChargeRulesArr(item, index) {
            let list = this.submitForm.list_topup_fix
            let nextItem = this.submitForm.list_topup_fix[index+1]
            
            // 如果有下一个，则给下一个对象开始加1
            if(nextItem) {
                // 如果当前修改的结束 >= 下一个对象的结束，则剩下的input全部清空
                if(Number(item.amt_end) >= Number(nextItem.amt_end)) {
                    for(let i=index+1; i<list.length; i++) {
                        list[i].give_value = ''
                        list[i].amt_start = ''
                        list[i].amt_end = ''
                    }
                }
                nextItem.amt_start = Number(item.amt_end) + 1
            }
        },
        delChargeRuleItem(index) {
            this.submitForm.list_topup_fix.splice(index, 1)
        },
        // public
        async initForm() {
             let params = {data: {ent_id: this.$store.state.ent_id}}
            let res = await query_member_rule_ming(params), data = {}
            if(res && res.code == 200) {
                console.log('res.body.data', res.body.data)
                data = res.body.data
                this.submitForm = {
                    ent_id: data.ent_id,
                    flag_mc_level: data.flag_mc_level,
                    flag_point: data.flag_point,
                    consume_amt: data.rule_point.consume_amt,
                    consume_point: data.rule_point.consume_point,
                    list_level: data.list_level,
                    flag_topup_fix: data.flag_topup_fix,   // 订单充值规则是否启用，1启用:0停用
                    flag_topup_order: data.flag_topup_order, // 固定充值规则是否启用，同上
                    give_type: data.give_type,  // 固定充值赠送类型
                    give_orig: data.rule_topup_order.give_orig,
                    give_all: data.rule_topup_order.give_all,
                    give_2: data.rule_topup_order.give_2,
                    give_3: data.rule_topup_order.give_3,
                    give_4: data.rule_topup_order.give_4,
                    list_topup_fix: data.list_topup_fix? data.list_topup_fix: []
                }
                this.useRules = []
                data.list_level.map(item=>{
                    if(item.flag_member_price=='1') this.useRules.push(item.level_id)
                })
                console.log('this.submitForm', this.submitForm)
            } else {
                this.submitForm = {
                    ent_id: this.$store.state.ent_id,
                    flag_mc_level: '1',
                    flag_point: '1',
                    consume_amt: '0',
                    consume_point: '0',
                    list_level: [{level_id: `${this.createParseDateTime()}`, point_start: 1, point_end: '', level_name: ''}],
                    flag_member_price: '1',
                    flag_topup_fix: '1',   // 订单充值规则是否启用，1启用:0停用
                    flag_topup_order: '1', // 固定充值规则是否启用，同上
                    give_type: '1',  // 固定充值赠送类型
                    give_orig: '',
                    give_all: '',
                    give_2: '',
                    give_3: '',
                    give_4: '',
                    list_topup_fix: [
                        {plan_id: `Fixed${new Date().getTime()}`, give_value: '', amt_start: '', amt_end: ''}
                    ]
                }
                if(res && res.code != 700) {
                    this.$message({message: res.msg, type: 'error'})
                }
            }
        },
        createParseDateTime() {
            return Date.parse(new Date())
        }
    }
}
</script>
<style scoped lang="scss">
.form_info_box{ padding: 0 40px; 
    .text_desc{ color: #333; margin: 0 10px; }
    .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
    .add_new{
        .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; border-radius: 6px; padding: 12px 20px; box-sizing: border-box; font-size: 14px; cursor: pointer;}
    }
    .charge_rule_btn{ margin-top: 20px; }
    .member_rules_list{
        li{ margin-bottom: 15px; }
    }
    .charge_rule{ display: flex; margin-bottom: 10px;
        span{ color: #333; margin: 0 5px; }
    }
    .order_charge_rule{
        span{ color: #333; margin: 0 5px; }
    }
}
</style>