<template>
    <div class="shop_foods">
        <el-cascader v-model="selectedArr" :options="list" :props="propsInfo" clearable @change="changecat" :key="setKey"></el-cascader>
    </div>
</template>
<script>
import {getShopFoodsList} from '../../utils/get_public_data.js'

export default {
    props: {
        propsInfo: {type: Object, default: ()=>{return {multiple: true, label:'e_show_label', value:'e_show_id', children: 'prod_children'}}},
        ent_id: {type: String | Number, default: ()=>''},
        shop_id: {type: String | Number, default: ()=>''},
        allSelected: {type: String, default: ()=>''},
        setKey: {type: String,default:()=>''}
    },
    data() {
        return {
            list: [],
            selectedArr: []
        }
    },
    mounted() {
        
    },
    methods: {
        async getFoodsList() {
            let list = []
            if(this.$store.state.foodsShopList && this.$store.state.foodsShopList.length > 0) {
                list = this.$store.state.foodsShopList
            } else {
                list = await getShopFoodsList(this.ent_id, this.shop_id)
                this.$store.commit('SET_SHOP_FOODS_LIST', list)
            }
            list.map(item=>{
                item['e_show_label'] = item.cat_name
                item['e_show_id'] = item.id
                item.prod_children.map(child=>{
                    child['e_show_label'] = child.prod_name
                    child['e_show_id'] = child.prod_id
                })
            })
            this.list = list
            this.initSelected()
        },
        // 回显数据处理
        initSelected() {
            if(this.allSelected) {
                let arr = this.allSelected.split(',')
                this.list.map(item=>{
                    item.prod_children.map(child=>{
                        arr.map(son=>{
                            if(child.prod_id == son){
                                this.selectedArr.push([item.id, child.prod_id])
                            }
                        })
                        
                    })
                })
            }
        },
        changecat(e) {
            let arr = []
            this.selectedArr.map(item=>{
                arr.push(item[1])
            })
            console.log('this.selectedArr, arr', this.selectedArr, arr)
            this.$emit('getSelectedFoods', this.selectedArr, arr)
        }
    },
    watch: {
        allSelected: function(v, o) {
            this.getFoodsList()
        }
    }
}
</script>