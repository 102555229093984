<template>
    <div class="pointex_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <!--
                // 
                // 积分兑换菜品
                // 
            -->
            <div class="MarketingCampaign-title"><span></span>积分兑换菜品</div>
            <el-form-item label="">
                <slot name="pointex_before"></slot>
            </el-form-item>
            <el-form-item label="" :class="{'bgf9': index%2}" v-for="(item, index) in list" :key="'f'+ index">
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">选择菜品</span>
                        <span class="con">
                            <el-cascader v-model="item.prod_id" :options="$store.state.foodsShopList" :props="{multiple: false, label:'e_show_label', value:'e_show_id', children: 'prod_children'}" clearable @change="changeFoodsList($event, index)" :key="'l-m'+ index"></el-cascader>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">需要积分</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="item.ex_point" style="width: 180px" @input="setInfo"></el-input>
                        </span>
                        <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">使用日期</span>
                        <el-date-picker v-model="item.useDatePicker" :picker-options="pickerOptions" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setInfo"></el-date-picker>
                        <span class="text_desc tit">可用日期</span>
                        <el-select v-model="item.date_type" multiple placeholder="请选择" dataName="日期选择" @change="setInfo">
                            <el-option v-for="item in dayList" :key="'date-'+item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item" style="display:flex; align-items: center;">
                        <span class="text_desc tit">使用时间</span>
                        <el-radio-group v-model="item.use_time_type" @change="useTimeTypeChange($event, item, index)" style="display:flex; align-items: center;">
                            <el-radio :label="'1'">早午晚市</el-radio>
                            <el-checkbox-group v-model="item.market_type" :disabled="!item.use_time_type || item.use_time_type == '2'" @change="setInfo" style="margin-right: 10px;">
                                <el-checkbox :label="child.id" v-for="child in marketList" :key="'daytype-'+child.id">{{child.label}}</el-checkbox>
                            </el-checkbox-group>
                            
                            <el-radio :label="'2'">时间区间
                                <el-time-picker is-range v-model="item.useTimePicker" :disabled="!item.use_time_type || item.use_time_type == '1'" value-format="HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="setInfo"></el-time-picker>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem()"> 新增积分兑换菜品</i>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import shopFoodsNew from '../../../foodsList/shopFoods_new.vue'
export default {
    props:{
        init_list: {type: Array, default: ()=>[]},
        shop_id: {type: String | Number, default: ()=>''},
        marketList: {type: Array, default: ()=>[]},
        dayList: {type: Array, default: ()=>[]}
    },
    data() {
        return {
            list: [],
            foodList: [],
            pickerOptions: {
                disabledDate(time) {
                return !(time.getTime() > Date.now() - 24*60*60*1000);
                }
            }
        }
    },
    components: {shopFoodsNew},
    mounted() {
        this.initForm()
    },
    methods: {
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        addItem() {
            this.list.push(this.formData())
        },
        initForm() { 
            this.list = [this.formData()]
        },
        useTimeTypeChange(v, item, index) {
            console.log('v, item, index', v, item, index, this.list[index].market_type)
            if(v=='1' && this.list[index].market_type.length <= 0) {
                console.log('zou--------')
                this.$set(this.list[index], 'market_type', ['1', '2', '3'])
                // this.list[index].market_type = ['1', '2', '3']
            }
        },
        setInfo() {
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                obj.use_date_start = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[0] : ''
                obj.use_date_end = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[1] : ''
                obj.use_time_start = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[0] : ''
                obj.use_time_end = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[1] : ''
                console.log('item.market_type', item.market_type)
                obj['market_type'] = item.market_type.join(',')
                obj['date_type'] = item.date_type.join(',')
                list.push(obj)
            })
            this.$emit('getList', list)
        },
        changeFoodsList(e, index) {
            console.log('e, index', e, index)
            // let idArr = []
            // e.map(item=>{
            //     idArr.push(item[1])
            // })
            this.list[index].prod_id = e[1]
            this.setInfo()
        },
        // getSelectedFoods(e, index) {
        //     let idArr = []
        //     e.map(item=>{
        //         idArr.push(item[1])
        //     })
        //     this.list[index].prod_id = idArr.join(',')
        //     this.setInfo()
        // },
        formData() {
            return {
                prod_id: '',
                ex_point: '',

                use_date_type: '1',
                date_type: [],
                use_date_start: '',
                use_date_end: '',
                use_time_type: '1',
                market_type: [],
                use_time_start: '',
                use_time_end: '',
                useDatePicker: [],// 提交时需要删除
                useTimePicker: null,
            }
        },
        verifyData() {
            let  __this = this
            console.log('this.list', this.list)
            return new Promise((resolve, reject)=>{
                let arr = __this.list
                for(let i=0; i<arr.length; i++) {
                    let item = arr[i]
                    for(let key in item) {
                        if(!item[key]){
                            let keyArr = __this.formDataToLabel()
                            for(let j=0;j<keyArr.length; j++) {
                                if(keyArr[j].key == key) {
                                    resolve({isError: true, title: `${keyArr[j].label}不能为空`, content: `积分兑换菜品第${i+1}个单位的${keyArr[j].label}不能为空`})
                                    return
                                }
                            }
                        }
                    }
                }
            })
        },
        formDataToLabel() {
            return [
                {key: 'prod_id', label: '菜品'},
                {key: 'ex_point', label: '积分数量'},
            ]
            
        }
    },
    watch: {
        init_list: function(v, o) {
                if(v && v.length > 0) {
                    let arr = []
                    v.map(item=>{
                        let obj = {}
                        for(let key in item) {
                            obj[key] = JSON.parse(JSON.stringify(item[key]))
                        }
                        if(item.use_date_start && item.use_date_end) obj['useDatePicker'] = [`${item.use_date_start}`, `${item.use_date_end}`]
                        else obj['useDatePicker'] = []
                        if(item.use_time_start && item.use_time_end) obj['useTimePicker'] = [`${item.use_time_start}`, `${item.use_time_end}`]
                        else obj['useTimePicker'] = null

                        
                        if(item.market_type && typeof item.market_type == 'string') obj['market_type'] = item.market_type.split(',').length >= 0? item.market_type.split(','): []
                        else obj['market_type'] = []
                        

                        if(typeof item.date_type == 'string') obj['date_type'] = item.date_type.split(',')
                        else obj['date_type'] = []
                        arr.push(obj)
                    })
                    this.list = arr
                
            } else {
                this.initForm()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>