<template>
    <div class="giftbag_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <div class="MarketingCampaign-title"><span></span>大礼包发放</div>
            <el-form-item label="" :class="{'bgf9': index%2}" v-for="(item, index) in list" :key="'g'+ index">
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">大礼包名称</span>
                        <span class="con">
                            <el-input min="0" placeholder="请输入" v-model="item.gift_name" style="width: 180px" @input="setInfo"></el-input>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">大礼包内容</span>
                        <span class="con">
                            <el-select v-model="item.coupon_id" multiple placeholder="请选择" style="width: 160px;" @change="setInfo">
                                <el-option v-for="(child, indx) in couponList" :key="'g-c' + indx" :label="child.prod_name" :value="child.cp_id"></el-option>
                            </el-select>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">发放数量</span>
                        <span class="con">
                            <el-input min="0" placeholder="请输入" v-model="item.give_num" style="width: 180px" @input="setInfo"></el-input>
                        </span>
                        <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem('giftList')"> 新增大礼包</i>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import bus from '../../../../utils/bus'
export default {
    props:{
        init_list: {type: Array, default: ()=>[]}
    },
    data() {
        return {
            list: [],
            couponList: []
        }
    },
    components: {},
    mounted() {
        this.initForm()
        bus.$on("couponList",e=>{
            this.couponList = e
        })
    },
    methods: {
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        addItem() {
            this.list.push(this.formData())
        },
        initForm() { 
            this.list = [this.formData()]
        },
        setInfo() {
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                obj.coupon_id = item.coupon_id.join(',')
                list.push(obj)
            })
            this.$emit('getList', list)
        },
        formData() {
            return {
                gift_name: '',
                coupon_id: '',
                give_num: ''
            }
        }
    },
    watch: {
        init_list: function(v, o) {
            if(v && v.length > 0) {
                let arr = []
                v.map(item=>{
                    let obj = item
                    if(typeof item.coupon_id == 'string') obj['coupon_id'] = item.coupon_id.split(',')
                    arr.push(obj)
                })
                this.list = arr
            } else {
                this.initForm()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>