<!-- // earl 2021-09-08 -->
<template>
    <div class="activity_rules">
        <!-- <div v-if="isShowcomp"> -->
        <el-form ref="form1" label-width="60px" class="form_info_box">
            <el-form-item label="">
                <ul class="rules_list">
                    <li style="display: flex; padding-top: 40px; margin-bottom:0;">
                        <span class="text_desc tit" style="width: 70px;">选择店铺</span>
                        <shopListByEntId :ent_id="$store.state.ent_id" :selected_id="submitForm.shop_id" @getAllShopList="getAllShopList" @getShopInfo="getShopInfo"></shopListByEntId>
                    </li>
                </ul>
            </el-form-item>
        </el-form>
        
        <!-- 权益券设置 -->
        <coupon-box ref="couponbox" :init_list="submitForm.right_coupon_list" :dayList="dayList()" :marketList="marketList()" :sexList="sexList()" @getList="getList($event, 'right_coupon_list')"></coupon-box>
        <!-- 大礼包设置 -->
        <giftbag-box ref="giftbagbox" :init_list="submitForm.gift_bag_list" :couponList="submitForm.right_coupon_list" @getList="getList($event, 'gift_bag_list')"></giftbag-box>
        <!-- 积分兑换菜 -->
        <pointex-box ref="pointexbox" :init_list="submitForm.point_ex_list" :dayList="dayList()" :marketList="marketList()" :shop_id="this.submitForm.shop_id" @getList="getList($event, 'point_ex_list')">
            <div slot="pointex_before" class="">
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">有效天数</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="paramsForm.valid_day_ex" style="width: 180px"></el-input>天
                        </span>
                    </div>
                </div>
            </div>
        </pointex-box>
        <!-- 拼团 -->
        <group-box ref="groupbox" :init_list="submitForm.tuan_list" :dayList="dayList()" :marketList="marketList()" :shop_id="this.submitForm.shop_id" @getList="getList($event, 'tuan_list')"></group-box>
        <!-- 砍价 -->
        <cut-box ref="cutbox" :init_list="submitForm.cut_list" :dayList="dayList()" :marketList="marketList()" :shop_id="this.submitForm.shop_id" @getList="getList($event, 'cut_list')" @getVerifi="getVerifi($event, 'cut_list')"></cut-box>
        <!-- 抽奖 -->
        <lottery-box ref="lotterybox" :init_list="submitForm.lottery_list" :dayList="dayList()" :marketList="marketList()" :shop_id="this.submitForm.shop_id" @getList="getList($event, 'lottery_list')">
            <div slot="lottery_before" class="">
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">有效天数</span>
                        <span class="con">
                            <el-input type="number" min="0" placeholder="请输入" v-model="paramsForm.valid_day_lottery" style="width: 180px"></el-input>天
                        </span>
                    </div>
                </div>
            </div>
        </lottery-box>
        <!-- 优惠限制 -->
        <limit-box ref="limitbox" :init_list="submitForm.right_limit_list" @getList="getList($event, 'right_limit_list')"></limit-box>

        <el-form ref="form" :model="submitForm" label-width="60px" class="form_info_box">
            <el-form-item label="">
                <div class="rules_row col_1">
                    <span class="text_desc tit">是否与会员价同时使用</span>
                    <span class="con">
                        <el-radio-group v-model="submitForm.flag_use_with_member">
                            <el-radio :label="'1'">是</el-radio>
                            <el-radio :label="'0'">否</el-radio>
                        </el-radio-group>
                    </span>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
        </el-form>
    <!-- </div> -->
    </div>
</template>
<script>
import {insert_action_set_hp, query_action_set_hp} from '../../../api/store'
import shopListByEntId from '../../shopList/shopListByEntId.vue'
import couponBox from './modules/coupon.vue'
import giftbagBox from './modules/giftbag'
import pointexBox from './modules/pointex'
import groupBox from './modules/group'
import cutBox from './modules/cut'
import lotteryBox from './modules/lottery.vue'
import limitBox from './modules/limit.vue'
export default {
    data() {
        return {
            isShowcomp: true,
            submitForm: {
                ent_id: this.$store.state.ent_id,
                user_id: this.$store.state.user_id,
                flag_use_with_member: '1'
            },
            paramsForm: {}, // 用来提交的表单
            shop_id: '',
            verifiForm: {},
            submitFormVerif: true
        }
    },
    components: {couponBox, giftbagBox, pointexBox, groupBox, cutBox, lotteryBox, limitBox, shopListByEntId},
    computed: {
        foodsShopList: function() {
            return this.$store.state.foodsShopList
        }
    },
    created() {
        
    },
    methods: {
        getList(list, key) {
            this.paramsForm[key] = list
            console.log('paramsForm', this.paramsForm)
        },
        getVerifi(list, key) {
            this.verifiForm[key] = list
            console.log('verifiForm', this.verifiForm)
        },
        submitVerify() {
            this.submitFormVerif = true
            let forInObj = this.verifiForm
            let offset = 50
            this.$notify.closeAll()
            for(let key in forInObj) {
                if(forInObj[key].length > 0) {
                    let arr = forInObj[key]
                    arr.map(item=>{
                        console.log('item', item.msg)
                        const h = this.$createElement
                        this.$notify({title: item.type, message: h('i', { style: 'color: teal'}, `${item.type}第${item.index+1}个单元，${item.msg}`), offset, duration:0})
                        offset = offset + 60
                        return
                    })
                    this.submitFormVerif = false
                }
            }
        },
        async onSubmit() {
            
            this.submitVerify()
            console.log('this.submitFormVerif', this.submitFormVerif, this.verifiForm)
            if(!this.submitFormVerif) {
                this.$message({message: '暂时不能提交，请看右侧提示!', type: 'error'})
                return
            }
            
            this.$refs.lotterybox.submitLotteryForm()

            let params = {data: {
                shop_id: this.paramsForm.shop_id,
                ent_id: this.$store.state.ent_id,
                user_id: this.$store.state.user_id,
                valid_day_ex: this.paramsForm.valid_day_ex,
                valid_day_lottery: this.paramsForm.valid_day_lottery,
                flag_use_with_member: this.submitForm.flag_use_with_member,
                right_coupon_list: this.formatSubmitDate_coupon(this.paramsForm.right_coupon_list),
                gift_bag_list: this.formatSubmitDate_gift(this.paramsForm.gift_bag_list),
                point_ex_list: this.paramsForm.point_ex_list,
                tuan_list: this.paramsForm.tuan_list,
                cut_list: this.formatSubmitDate_cut(this.paramsForm.cut_list),
                lottery_list: this.paramsForm.lottery_list,
                right_limit_list: this.formatSubmitDate_limit(this.paramsForm.right_limit_list)
            }}
            console.log('params', params)
            let res = await insert_action_set_hp(params)
            if(res && res.code == 200) {
                this.$message({message: '恭喜您，完成提交!', type: 'success'})

            } else {
                this.$message({message: res.msg, type: 'error'})
            }
        },
        async getDetail(shop_id) {
            this.isShowcomp = false
            let params = {data:{ent_id: this.$store.state.ent_id, shop_id: shop_id}}
            let res = await query_action_set_hp(params)
            if(res && res.code == 200) {
                // this.$refs.lotterybox.initLotteryData()
                let form = res.body.data
                console.log('活动设置------', form)
                this.submitForm = form
                this.paramsForm = JSON.parse(JSON.stringify(form))
                
                if(!form.shop_id) {
                    this.submitForm.shop_id= shop_id
                    this.submitForm.ent_id= this.$store.state.ent_id
                    this.submitForm.user_id= this.$store.state.user_id
                    this.submitForm.flag_use_with_member= '1'
                }
            } else {
                this.$message({message: res.msg, type: 'error'})
            }
            this.isShowcomp = true
        },
        getAllShopList(list) {
            // 获取完店铺后默认使用第一个店铺积分设置
            if(list && list.length > 0) {
                this.$store.dispatch('setFoodsList', {ent_id:this.$store.state.ent_id, shop_id: list[0].shop_id})
                this.shop_id = list[0].shop_id
            }
        },
        getShopInfo(v, item) {
            this.$store.dispatch('setFoodsList', {ent_id:this.$store.state.ent_id, shop_id: v})
            this.shop_id = v
        },
        formatSubmitDate_coupon(arr) {
            let list = []
            arr.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }                
                obj.date_start = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[0] : item.date_start
                obj.date_end = item.useDatePicker && item.useDatePicker.length > 0 ? item.useDatePicker[1] : item.date_end
                obj.time_start = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[0] : item.time_start
                obj.time_end = item.useTimePicker && item.useTimePicker.length > 0 ? item.useTimePicker[1] : item.time_end
                if(typeof item.condition_sex != 'string') obj['condition_sex'] = item.condition_sex.join(',')
                if(typeof item.market_type != 'string') obj['market_type'] = item.market_type.join(',')
                if(typeof item.date_type != 'string') obj['date_type'] = item.date_type.join(',')
                delete obj.useDatePicker
                delete obj.useTimePicker
                list.push(obj)
            })
            return list
        },
        formatSubmitDate_gift(arr) {
            console.log('gift', arr)
            let list = []
            arr.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                // obj.coupon_id = (typeof item.coupon_id != 'string') ? item.coupon_id.join(',') : item.coupon_id
                list.push(obj)
            })
            return list
        },
        formatSubmitDate_cut(arr) {
            let list = []
            arr.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                delete obj.datePicker
                list.push(obj)
            })
            return list
        },
        formatSubmitDate_limit(arr) {
            let list = []
            arr.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                delete obj.limitArr
                list.push(obj)
            })
            return list
        },
        dayList() {
            return [
                {id: '1', label: '工作日'},
                {id: '2', label: '周末'},
                {id: '3', label: '节假日'},
                {id: '4', label: '生日'}
            ]
        },
        marketList() {
            return [
                {id: '1', label: '早市'},
                {id: '2', label: '午市'},
                {id: '3', label: '晚市'}
            ]
        },
        sexList() {
            return [
                {id: '1', label: '男'},
                {id: '0', label: '女'}
            ]
        }
    },
    watch: {
        foodsShopList: function(v, o) {
            this.getDetail(this.shop_id)
        }
    }
}
</script>
<style scoped lang="scss">
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; border-radius: 6px; padding: 3px 10px; font-size: 13px; cursor: pointer; margin-right: 15px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>