export const mixin = {
    data() {
        return {
            foodsListArr: [],
            allSelected: [],
            selectedArr: [],
            propsInfo: {multiple: true, label:'e_show_label', value:'e_show_id', children: 'prod_children'},
        }
    },
    methods: {
        async getFoodsList() {
            let list = []
            if(this.$store.state.foodsShopList && this.$store.state.foodsShopList.length > 0) {
                list = this.$store.state.foodsShopList
            } else {
                list = await getShopFoodsList(this.$store.state.ent_id, this.shop_id)
                this.$store.commit('SET_SHOP_FOODS_LIST', list)
            }
            list.map(item=>{
                item['e_show_label'] = item.cat_name
                item['e_show_id'] = item.id
                item.prod_children.map(child=>{
                    child['e_show_label'] = child.prod_name
                    child['e_show_id'] = child.prod_id
                })
            })
            this.foodsListArr = list
            this.initSelected()
        },
        // 回显数据处理
        initSelected() {
            if(this.allSelected) {
                let arr = this.allSelected.split(',')
                this.foodsListArr.map(item=>{
                    item.prod_children.map(child=>{
                        arr.map(son=>{
                            if(child.prod_id == son){
                                this.selectedArr.push([item.id, child.prod_id])
                            }
                        })
                        
                    })
                })
            }
        },
        changecat(e) {
            let arr = []
            this.selectedArr.map(item=>{
                arr.push(item[1])
            })
            this.allSelected = arr
        }
    }
  }