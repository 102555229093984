<!-- // earl 2021-09-08 -->
<template>
    <div class="integral_rules">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <el-form-item label="">
                <ul class="rules_list">
                    <li style="display: flex; padding-top: 40px; margin-bottom:0;">
                        <span class="text_desc tit" style="width: 100px">选择店铺</span>
                        <shopListByEntId :ent_id="$store.state.ent_id" :selected_id="submitForm.shop_id" @getAllShopList="getAllShopList" @getShopInfo="getShopInfo"></shopListByEntId>
                    </li>
                </ul>
            </el-form-item>
            <!-- 基础规则 -->
            <div class="MarketingCampaign-title"><span></span>基础规则</div>
            <el-form-item label="">
                <ul class="rules_list x">
                    <li>
                        <span class="text_desc tit">注册获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.reg_get" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                    </li>
                    <li>
                        <span class="text_desc tit">签到获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.sign_get" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                    </li>
                </ul>
                <ul class="rules_list x">
                    <li>
                        <span class="text_desc tit">储值消费获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.consume_get" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                    </li>
                    <li class="reply_style">
                        <span class="text_desc tit">评价获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.review_get" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                    </li>
                </ul>
                <ul class="rules_list x">
                    <li style="width: 100%;">
                        <span class="text_desc tit">获得规则</span>
                        <span class="text_desc">字数</span><el-input type="number" min="0" placeholder="请输入" v-model="submitForm.review_get_text" style="width: 100px"></el-input><span class="text_desc">字以上，</span>
                        <span class="text_desc">图片</span><el-input type="number" min="0" placeholder="请输入" v-model="submitForm.review_get_pic" style="width: 100px"></el-input><span class="text_desc">张以上</span>
                        <span class="text_desc" style="color: red;">或</span>
                        <span class="text_desc">视频</span><el-input type="number" min="0" placeholder="请输入" v-model="submitForm.review_get_video" style="width: 100px"></el-input><span class="text_desc">个以上</span>
                    </li>
                </ul>
            </el-form-item>
            <!-- 拼团分享规则 -->
            <div class="MarketingCampaign-title"><span></span>拼团分享规则</div>
            <el-form-item label="">
                <ul class="rules_list">
                    <li>
                        <span class="text_desc tit">注册成功分享人获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.tuan_share_reg" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                        <span class="shop_tip">(分享人得积分)</span>
                    </li>
                    <li>
                        <span class="text_desc tit">点击者与上一级分享人获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.tuan_share_buy" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                        <span class="shop_tip">(购买成功得积分)</span>
                    </li>
                </ul>
            </el-form-item>
            <!-- 砍价分享规则 -->
            <div class="MarketingCampaign-title"><span></span>砍价分享规则</div>
            <el-form-item label="">
                <ul class="rules_list">
                    <li>
                        <span class="text_desc tit">注册成功分享人获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.cut_share_reg" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                        <span class="shop_tip">(分享人得积分)</span>
                    </li>
                    <li>
                        <span class="text_desc tit">分享人砍价成功获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.cut_share_buy" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                        <span class="shop_tip">(现金购买后)</span>
                    </li>
                </ul>
            </el-form-item>
            <!-- 社群分享规则 -->
            <div class="MarketingCampaign-title"><span></span>社群分享规则</div>
            <el-form-item label="">
                <ul class="rules_list">
                    <li>
                        <span class="text_desc tit">注册成功分享人获得</span>
                        <el-input type="number" min="0" placeholder="请输入" v-model="submitForm.group_share_reg" style="width: 180px">
                            <template slot="append">积分</template>
                        </el-input>
                        <span class="shop_tip">(分享人得积分)</span>
                    </li>
                </ul>
            </el-form-item>
            <el-form-item>
                <div style="padding-left: 60px;"><el-button type="primary" @click="onSubmit">立即创建</el-button></div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {query_rule_point_hp, insert_point_rule_hp} from '../../../api/store'
import shopListByEntId from '../../shopList/shopListByEntId.vue'
export default {
    data() {
        return {
            submitForm: {
                ent_id: this.$store.state.ent_id
            }
        }
    },
    components: {shopListByEntId},
    created() {
        
    },
    methods: {
        async onSubmit() {
            let params = {data: this.submitForm}
            let res = await insert_point_rule_hp(params)
            if(res && res.code == 200) this.$message({message: '恭喜您，完成提交！', type: 'success'})
            else this.$message({message: res.msg, type: 'error'})
        },
        // public
        async initForm(shop_id) {
            let formD = {
                ent_id: this.$store.state.ent_id,
                shop_id: shop_id,
                reg_get: '',
                sign_get: '',
                consume_get: '',
                review_get: '',
                review_get_text: '',
                review_get_pic: '',
                review_get_video: '',
                tuan_share_reg: '',
                tuan_share_buy: '',
                cut_share_reg: '',
                cut_share_buy: '',
                group_share_reg: ''
            }

            let params = {data: {ent_id: this.$store.state.ent_id, shop_id: shop_id}}
            let res = await query_rule_point_hp(params)
            // 如果查询到数据则使用查询数据，否则使用默认数据
            if(res && res.code == 200) {
                if(res.body.data && res.body.data != 'null')  this.submitForm = res.body.data
                else this.submitForm = formD
            } else {
                this.$message({message: res.msg, type: 'error'})
            }
        },
        getAllShopList(list) {
            // 获取完店铺后默认使用第一个店铺积分设置
            if(list && list.length > 0) this.initForm(list[0].shop_id)
            else this.initForm()
        },
        getShopInfo(v, item) {
            this.initForm(v)
        }
    }
}
</script>
<style scoped lang="scss">
.form_info_box{ padding: 0 40px; 
    .text_desc{ color: #333; margin: 0 10px; }
    .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
    .add_new{
        .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; border-radius: 6px; padding: 3px 10px; font-size: 13px; cursor: pointer;}
    }
    .rules_list{ display: block;
        li{ margin-bottom: 15px; }
        .tit{ display: inline-block; width: 180px; text-align: right; }
        .reply_style{ flex: 1; }
        &.x{ display: flex;
             li{ width: 300px; 
                .tit{ width: 100px; }
             }
        }
    }
    .shop_tip{ color: #666; margin-left: 15px; }
}
</style>