<template>
  <div class="MarketingCampaign-box">
    <div class="Mar-nav">
      <!-- <p :class="navindex == 0 ? 'psel':''" @click="handlechange(0)">营销图设置</p> -->
      <p :class="navindex == 1 ? 'psel':''" @click="handlechange(1)">优惠活动设置</p>
      <p :class="navindex == 2 ? 'psel':''" @click="handlechange(2)">积分规则</p>
      <p :class="navindex == 3 ? 'psel':''" @click="handlechange(3)">商家活动设置</p>
    </div>
    <div class="MarketingCampaign">

      <div v-if="navindex == 0">
        <div class="MarketingCampaign-title">
          <span></span>
          Banner轮播图

          <!-- <div>
            <span style="font-size:14px; margin-left:120px;margin-right:20px;">设置门店</span>
            <el-select v-model="cur_shop" @change="cur_shop_change" placeholder="请选择" size="small">
              <el-option v-for="item in shopOption" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                <span style="float: left">{{ item.shop_name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{item.status}}</span>
              </el-option>
            </el-select>
          </div> -->
        </div>
          <p class="MarketingCampaign-p">
            用于展示店铺banner，请上传JPG/JPEG/PNG的图片，最多可上传3张。 (建议比例510：214)
          </p>
        <div>

        </div>

        <div class="shopOption">
          <span>适用门店：</span>

          <el-select v-model="cur_shop" @change="cur_shop_change" placeholder="请选择" size="small">
            <el-option v-for="item in shopOption" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
              <span style="float: left">{{ item.shop_name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{item.status}}</span>
            </el-option>
          </el-select>

        </div>
        <div class="MarketingCampaign-collapse">
          <el-collapse accordion>
            <!-- 营销 -->
            <el-collapse-item>
              <template slot="title">
                营销图（{{yingxiaolist == '' ? '0': MarketList.length}}/3）
              </template>
              <div class="piclist">
                <el-upload
action="#"
list-type="picture-card"
:auto-upload="false"
:limit='3'
                  :on-change="handlechangeimg"
:file-list="MarketList"
:before-upload="beforeAvatarUpload"

>
                  <i slot="default" class="el-icon-picture-outline">上传图片</i>
                  <div slot="file" slot-scope="{ file }" ref="file" class="file-box">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file,1)">
                        x
                      </span>
                      <span>审核中</span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-collapse-item>
            <!-- 健康 -->
            <el-collapse-item>
              <template slot="title">
                健康证明（{{jiankanglist == '' ? '0' : HeaList.length}}/10）
              </template>
              <div>
                <el-upload
action="#"
list-type="picture-card"
:auto-upload="false"
:limit='10'
                  :on-change="handlechangeimg1"
:file-list="HeaList"
:before-upload="beforeAvatarUpload"
>
                  <i slot="default" class="el-icon-picture-outline">上传图片</i>
                  <div slot="file" slot-scope="{ file }" ref="file" class="file-box">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file,2)">
                        x
                      </span>
                      <span>审核中</span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-collapse-item>
            <!-- 餐厅环境 -->
            <el-collapse-item>
              <template slot="title">
                餐厅环境（{{cantinglist == '' ? '0' : EnvList.length}}/10）
              </template>
              <div>
                <el-upload
action="#"
list-type="picture-card"
:auto-upload="false"
:limit='10'
                  :on-change="handlechangeimg2"
:file-list="EnvList"
:before-upload="beforeAvatarUpload">
                  <i slot="default" class="el-icon-picture-outline">上传图片</i>
                  <div slot="file" slot-scope="{ file }" ref="file" class="file-box">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file,3)">
                        x
                      </span>
                      <span>审核中</span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="save">
          <el-button type="primary" @click="handlesave">保存</el-button>
        </div>

      </div>

      <!-- earl 2021-09-06 -->
      <setting-rules  v-if="navindex == 1"></setting-rules>
      <integral-rules  v-if="navindex == 2"></integral-rules>
      <activity-rules  v-if="navindex == 3"></activity-rules>


<!-- -------------以下为earl前任写的，已经屏蔽----------------- -->
      <div v-if="0==1">
        <div class="MarketingCampaign-title">
          <span></span>
          优惠活动设置
        </div>
        <div class="MarketingCampaign-form">

          <el-form ref="form" :model="form" label-width="124px">

            <!-- <el-form-item label="充值规则：">
              <el-radio-group v-model="form.flag_topup">
                <el-radio :label="'1'">启用</el-radio>
                <el-radio :label="'0'">停用</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="">
              <div class="refill-box" v-for="(item,i) in form.list_topup" :key="i">
                <span>充值</span>
                <input v-model="item.amt_topup" :disabled="form.flag_topup == '0'" class="refill-input" />
                <span>元，送</span>
                <input v-model="item.amt_give" :disabled="form.flag_topup == '0'" class="refill-input" />
                <span>元</span>
                <span class="del" @click="handledelrec(i)">删除</span>
              </div>
              <p class="AddSolution" @click="handleRec()">增加方案</p>
            </el-form-item> -->

            <el-form-item label="会员规则：">
              <el-radio-group v-model="form.flag_mc_level">
                <el-radio label="1" class="memberRadio">启用</el-radio>
                <el-radio label="0">停用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="会员卡名称：">
              <div class="refill-box">
                <input
v-model="form.vip_name"
style="width:200px"
:disabled="form.flag_mc_level=='0'"
                  class="memberInput" />

              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="refill-box" v-for="(item,i) in form.list_level" :key="i">

                <br>
                <span>充值</span>
                <input v-model="item.amt_topup" :disabled="form.flag_mc_level=='0'" class="memberInput" />

                <span>元，送</span>
                <input v-model="item.amt_give" :disabled="form.flag_mc_level == '0'" class="refill-input" />
                <span>元，成为</span>
                <!-- <span>元，送</span>
                <input v-model="item.amt_give" :disabled="form.flag_topup == '0'" class="refill-input" />
                <span>元</span> -->

                <input v-model="item.level_name" :disabled="form.flag_mc_level=='0'" class="memberInput" />
                <span>会员，享受</span>
                <input v-model="item.discount" :disabled="form.flag_mc_level=='0'" class="memberInput" />
                <span>%折扣，</span>
                <span>不参与折扣菜品</span>
                <!-- <el-select class="give" v-model="item.value" placeholder="请选择">
                  <el-option class="giveone" v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select> -->
                <!-- <el-select v-model="item.prod_ids" :disabled="form.flag_mc_level=='0'" multiple placeholder="请选择">
                  <el-option v-for="item in shopOption" :key="item.value" :label="item.label" :value="item.value">
                  </el-option> -->
                <!-- </el-select> -->

                <el-cascader
v-model="item.SCLIST"
:disabled="form.flag_mc_level=='0'"
:props="propsSC"
                  :options="SCLIST"
style="width:200px"></el-cascader>

                <span class="del" @click="handledelint(i)">删除</span>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div>
                <p class="AddSolution" @click="handleintegral()">增加方案</p>
              </div>
            </el-form-item>

            <el-form-item label="积分规则：">
              <el-radio-group v-model="form.flag_point">
                <el-radio label="1" class="memberRadio">启用</el-radio>
                <el-radio label="0">停用</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="">
              <div class="refill-box">
                <span>消费</span>
                <input v-model="form.consume_amt" :disabled="form.flag_point=='0'" class="integralInput" />
                <span>元，获得</span>
                <input v-model="form.consume_point" :disabled="form.flag_point=='0'" class="integralInput" />
                <span>积分</span>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="refill-box">
                <span>使用</span>
                <input v-model="form.use_point" :disabled="form.flag_point=='0'" class="integralInput" />
                <span>积分，抵扣</span>
                <input v-model="form.use_amt" :disabled="form.flag_point=='0'" class="integralInput" />
                <span>元</span>
              </div>
            </el-form-item>

            <el-form-item label="优惠券设置：">

              <el-radio-group v-model="form.flag_coupon">
                <el-radio label="1" class="memberRadio">启用</el-radio>
                <el-radio label="0">停用</el-radio>
              </el-radio-group>

            </el-form-item>

            <el-collapse>
              <el-form-item v-for="(item,i) in form.list_coupon" :key="i">
                <el-collapse-item :title="`方案${i+1}`">
                  <el-form-item label="适用门店：">
                    <el-select
:disabled="form.flag_coupon=='0'"
multiple
v-model="item.shop_id"
@change="handleToplist"
                      placeholder="请选择">
                      <el-option
v-for="item in shopOption"
:key="item.shop_id"
:label="item.shop_name"
                        :value="item.shop_id">
                      </el-option>
                    </el-select>
                    <span style="color:#ff5e41;margin-left:20px" @click="handledeldis(i)">删除</span>
                  </el-form-item>

                  <el-form-item label="优惠券名称：">
                    <input
v-model="item.coupon_name"
:disabled="form.flag_coupon=='0'"
class="discountCouponInput"
                      placeholder="请输入" />
                  </el-form-item>

                  <div class="DiscountWay">
                    <el-form-item label="抵扣方式：" style="margin-right:200px">
                      <el-radio-group v-model="item.type_use" :disabled="form.flag_coupon=='0'">
                        <el-radio label="1" class="DiscountRadio">抵现</el-radio>
                        <el-radio label="2">折扣</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <el-form-item label="">
                    <label for="" class="CouponInput-box">
                      <input v-model="item.value_use" :disabled="form.flag_coupon=='0'" class="CouponInputOne" />
                      <span class="Many" v-if=" item.type_use == '1'">元</span>
                      <span class="Many" v-else>折</span>
                    </label>
                  </el-form-item>
                  <el-form-item label="有效期限：">
                    <div class="block">
                      <!-- <el-date-picker v-model="item.date_valid" value-format="yyyy-MM-dd" :disabled="form.flag_coupon=='0'" type="date"
                        style="width:250px;height:36px;" placeholder="选择日期" :picker-options="pickerOptions">
                      </el-date-picker> -->

                      <el-date-picker
v-model="item.date_valid"
value-format="yyyy-MM-dd"
type="daterange"
                        range-separator="至"
:start-placeholder="item.date_valid_start"
:picker-options="pickerOptions"
                        :end-placeholder="item.date_valid_end">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="使用日期：">
                    <el-select
v-model="item.type_use_date"
:disabled="form.flag_coupon=='0'"
multiple
placeholder="请选择"
                      style="width:260px;height:36px;">
                      <!-- <el-option label="工作日" value="1"></el-option>
                  <el-option label="周末" value="2"></el-option>
                  <el-option label="节假日" value="3"></el-option> -->
                      <el-option v-for="work in gzr_options" :key="work.value" :label="work.label" :value="work.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="使用时间：">
                    <!-- <el-time-picker is-range  v-model="item.timeDY" :disabled="form.flag_coupon=='0'" value-format="hh:mm" format="hh:mm"
                      range-separator="至"   start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="test">
                    </el-time-picker> -->

                    <el-time-picker
is-range
v-model="form.list_coupon[i].timeDY"
value-format="HH:mm"
format="HH:mm"
                      :disabled="form.flag_coupon=='0'"
range-separator="至"
start-placeholder="开始时间"
                      end-placeholder="结束时间"
placeholder="选择时间范围">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="使用规则：">
                    <span class="rule-span">满</span>
                    <el-input
type="text"
class="ruleInput"
:disabled="form.flag_coupon=='0'"
                      v-model="item.amt_condition" />
                    <span class="rule-span">元,可用</span>
                  </el-form-item>
                  <el-form-item label="发送范围：">
                    <el-select
v-model="item.scope"
placeholder="请选择"
:disabled="form.flag_coupon=='0'"
                      style="width:260px;height:36px;">
                      <el-option label="所有人" value="1"></el-option>
                      <el-option label="三十天内未消费" value="2"></el-option>
                      <el-option label="新用户" value="3"></el-option>
                      <el-option label="会员" value="4"></el-option>
                    </el-select>
                  </el-form-item>


                  <el-form-item label="发售预计总量：" >
                    <input
v-model="item.give_total"
:disabled="form.flag_coupon=='0'"
class="discountCouponInput"
                      placeholder="请输入" />
                  </el-form-item>

                </el-collapse-item>
              </el-form-item>
            </el-collapse>

            <!-- <div > -->

            <p class="AddSolution coupon" @click="handleadddis()">添加优惠卷</p>

            <div class="div_ceshi">
              <div class="div_xaunz">
                <span>渠道问卷设置：</span>
                <el-radio-group v-model="form.flag_chan">
                  <el-radio label="1">启用</el-radio>
                  <el-radio label="0">停用</el-radio>
                </el-radio-group>
              </div>

              <div class="div_ceshiS" style="width:150px;">
                <el-form-item label="请选择优惠券">
                  <el-select
v-model="form.flag_index"
:disabled='form.flag_chan=="0"'
placeholder="请选择"
                    style="width:260px;height:36px;">
                    <el-option
:label="'方案'+(index+1)"
:value="item.coupon_id"
v-for="(item,index) in form.list_coupon"
                      :key="index"></el-option>

                    <!-- <el-option label="三十天内未消费" value="2"></el-option>
                    <el-option label="新用户" value="3"></el-option> -->
                  </el-select>
                </el-form-item>
              </div>

              <div class="div_ceshiS">
                <el-form-item label="发送范围：">
                  <el-select
v-model="form.chan_scope"
:disabled='form.flag_chan=="0"'
placeholder="请选择"
                    style="width:260px;height:36px;">
                    <el-option label="所有人" value="1"></el-option>
                    <el-option label="三十天内未消费" value="2"></el-option>
                    <el-option label="新用户" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="div_ceshiS">
                <el-form-item label="问卷标题：">
                  <el-input type="text" disabled value="请问您是从什么渠道到店用餐？" placeholder="" />
                </el-form-item>
              </div>
              <div class="div_ceshiS">
                <div class="div_ceshi_box">
                  <div v-for="(v,i) in form.list_chan" :key="i">
                    <el-form-item label="选项：">
                      <el-input type="text" :disabled='form.flag_chan=="0"' v-model="v.option_name" placeholder="" />
                      <span @click="deleteNode(i)" style="margin-left:20px,color:#ff5e41">删除</span>
                    </el-form-item>
                  </div>
                  <div v-on:click="addNode()">
                    <p class="addwei"> 添加选项 </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="div_ceshi">
              <div class="div_xaunz">
                <span>短信模板设置：</span>

                <el-radio-group v-model="form.flag_msg">
                  <el-radio label="1">启用</el-radio>
                  <el-radio label="0">停用</el-radio>
                </el-radio-group>
              </div>

              <div class="div_ceshiS ">

                <div v-for="(item,i) in form.list_msg" :key="i">
                  <el-form-item label="发送范围：">
                    <el-select
v-model="item.scope"
:disabled='form.flag_msg=="0"'
placeholder="请选择"
                      style="width:260px;height:36px;">
                      <el-option label="所有人" value="1"></el-option>
                      <el-option label="三十天内未消费" value="2"></el-option>
                      <el-option label="新用户" value="3"></el-option>
                      <el-option label="会员" value="4"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="发送人：">
                    <!-- <el-select v-model="item.role_id" :disabled='form.flag_msg=="0"' @change="handleToplist" multiple
                      placeholder="请选择">
                      <el-option v-for="item in jiaoseOption" :key="item.role_group_id" :label="item.role_group_name"
                        :value="item.role_group_id">
                      </el-option>
                    </el-select> -->

                    <el-cascader
v-model="item.role_id"
:show-all-levels="false"
:options="jiaoseOption"
                      placeholder="请选择角色"
:props="defaultProps">
                    </el-cascader>
                  </el-form-item>

                  <el-form-item label="消息名称：">
                    <el-input type="text" :disabled='form.flag_msg=="0"' placeholder="请输入消息名称" v-model="item.temp_name">
                    </el-input>

                  </el-form-item>

                  <div class="div_vip" style="margin-top:20px">
                    <el-input
type="textarea"
:rows="6"
:disabled='form.flag_msg=="0"'
placeholder="编辑信息模板"
                      v-model="item.text"></el-input>
                    <img src="../../../assets/img/rtop.jpg" alt="">
                    <span @click="handledelsms(i)">删除</span>
                  </div>

                </div>

                <p class="addwei" @click="handlesms"> 增加方案 </p>
              </div>
            </div>

            <el-form-item label="">
              <p style="font-size:16px;color:#333">优惠券与会员价是否可用时享用</p>
              <el-radio-group v-model="form.flag_member_coupon">
                <el-radio :label="'1'">可同时享用</el-radio>
                <el-radio :label="'0'">不可同时享用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="formSureBox">
            <button class="formSure" @click="formSure">确定</button>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import {
  query_shop_info_list,
  replace_ent_img,
  query_ent_img_list,
  insert_member_rule,
  query_member_rule,
  query_cat_prod_list_of_tree,
  query_role_group_info_list,
  query_ent_img_shop_list
} from '../../../api/store'

import {
  query_category_tree,
  delete_ent_img
} from '../../../api/public'
import {
  query_prod_tree_of_1cat_2prod
} from '../../../api/foodqiye'

import {
  mapActions
} from 'vuex'
const gzr_options = [{
  label: '工作日',
  value: '1'
},
{
  label: '周末',
  value: '2'
},
{
  label: '节假日',
  value: '3'
}
]
// earl
import settingRules from './settingRules.vue'
import integralRules from './integralRules.vue'
import activityRules from './activityRules.vue'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7 // 禁用以前的日期，今天不禁用
          // return date.getTime() <= Date.now();    //禁用今天以及以前的日期
        }
      },
      jiaoseOption: [],
      gzr_options: gzr_options,
      cur_shop: '',
      props: {
        value: 'shop_id',
        label: 'shop_name',
        children: 'children',
        multiple: true
      },
      defaultProps: {
        emitPath: false,
        checkStrictly: false,
        multiple: true,
        children: 'role_list',
        label: 'role_name',
        value: 'role_id'
      },
      SCLIST: [], // 这里选择菜的二级
      propsSC: {
        value: 'cat_id',
        label: 'cat_name',
        children: 'prod_children',
        multiple: true
      },
      allImg: [],
      channal: '',
      vipdate: '',
      startime: '',
      endtime: '',
      navindex: '1',
      // activeIndex: '1',
      infoset: '1',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      textarea: '',
      using: '1',
      couponset: '1',
      form: {
        vip_name: '', // 会员卡名称
        flag_member_coupon: '0',
        // flag_topup: '0', // 充值规则是否启用：1启用，0不启用
        flag_mc_level: '0', // 会员等级是否启用：1启用，0不启用 ?
        flag_msg: '0', // 消息是否启用：1启用，0不启用
        flag_point: '0', // 积分是否启用：1启用，0不启用
        flag_chan: '0', // 渠道问卷是否启用：1启用，0不启用
        flag_coupon: '0', //	优惠券是否启用：1启用，0不启用
        // flag_member_price: this.value, //是否设置会员价  1  是
        // flag_member_coupon: this.MemberRadio, //优惠  会员 同时使用？  1可以 0不
        consume_point: '',
        consume_amt: '',
        use_point: '', //	使用x积分
        use_amt: '', //	产生

        list_level: [{
          level_id: '',
          level_name: '',
          amt_topup: '', // 充值金额
          discount: '', // 会员折扣
          prod_ids: [] // 赠菜，逗号分割
        }], // 会员规则

        list_coupon: [{

          shop_id: [],
          coupon_id: '',
          coupon_name: '', // 优惠券名称
          type_use: '1', // 抵扣方式：1抵现，2折扣
          value_use: '', // 抵扣数值：抵现x元，或x折
          date_valid: [], // 有效期限
          type_use_date: '1', // 使用日期：1工作日，2周末，3节假日
          time_start: '', // 使用时间 开始
          time_end: '', // 使用时间 结束
          amt_condition: '', // 使用规则：满x元，可用
          scope: '1', // 范围：1所有人，2三十天内未消费，
          give_total:''// 发售预计总数 
        }],

        scope: this.channal, // 1所有人，2三十天内未消费，3新用户
        list_chan: [{
          option_id: '',
          option_name: ''
        }],
        list_msg: [{
          temp_id: '',
          temp_name: '',
          text: '',
          scope: '',
          role_id: ''
        }],
        chan_scope: '1'
      },
      shopOption: [],
      catList: [],
      shop: '',
      shopS: '',
      radio: '1',
      memberRadio: '1',
      integralRadio: '1',
      DiscountWayRadio: '1',
      DiscountRadio: '2',
      MemberRadio: '1',
      // 时间选择
      time_select: '',
      time_use: '',
      value: '',
      valueS: '',

      consume_point: '', //	消费x元
      consume_amt: '', //	产生
      use_point: '', //	使用x积分
      use_amt: '', //	产生
      list: [{
        option_id: '', // 选项id
        option_name: '', // 选项名称
        scope: '' // 范围
      }],
      Recharge: [{
        plan_name: '',
        operate_time: '',
        level_id: '1', //
        amt_topup: '', // 充值
        amt_give: '' // 给予
      }], // 充值规则
      Vip: [{
        level_id: '',
        level_name: '',
        amt_topup: '', // 充值金额
        discount: '', // 会员折扣
        prod_ids: '' // 赠菜，逗号分割
      }], // 会员规则
      coupon: [{

        shop_id: '',
        coupon_id: '',
        coupon_name: '', // 优惠券名称
        type_use: '', // 抵扣方式：1抵现，2折扣
        value_use: '', // 抵扣数值：抵现x元，或x折
        date_valid: [], // 有效期限
        type_use_date: '', // 使用日期：1工作日，2周末，3节假日
        time_start: '', // 使用时间 开始
        time_end: '', // 使用时间 结束
        amt_condition: '', // 使用规则：满x元，可用
        scope: '' // 范围：1所有人，2三十天内未消费，

      }], // 优惠券
      sms: [{
        temp_id: '', //	消息id
        //   temp_name: '',//	消息名称
        text: '', //	消息内容
        scope: '', //	范围：1所有人，2三十天内未消费，3新用户
        role_id: '' // 角色id  多选
      }], // 短信模板
      topSelList: '',
      imgtobase64: '',
      options: [],
      value: '',
      yingxiaolist: '',
      jiankanglist: '',
      cantinglist: '',

      imglist: [],
      imglists: [],
      imglistss: [],

      allList: [],
      MarketList: [],
      HeaList: [],
      EnvList: [],
      allImgIdList: []
    }
  },
  created() {
    query_prod_tree_of_1cat_2prod({
      data: {
        ent_id: this.$store.state.ent_id
      }
    }).then(res => {
      for (const i in res.body.data) {
        res.body.data[i].prod_id = res.body.data[i].cat_id
        res.body.data[i].prod_name = res.body.data[i].cat_name
      }
      this.SCLIST = res.body.data
    })
    const self = this
    this.propsSC = {
      lazy: false,
      emitPath: false,
      label: 'prod_name',
      value: 'prod_id',
      children: 'prod_children',
      multiple: true,
      checkStrictly: true,
      lazyLoad(node, resolve) {
        //
        if (node.level === 0) {}
        if (node.level > 0) {
          query_cat_prod_list_of_tree({
            data: {
              ent_id: self.$store.state.ent_id,
              cat_id: node.data.cat_id
            }
          }).then(res => {
            const arr = []
            res.body.data.forEach(item => {
              item.cat_id = item.prod_id
              item.cat_name = item.prod_name
              arr.push(item)
            })

            return resolve(arr)
          })
        }
      }
    },

    this.get_cat_list()

    this.GetRule()
    // 获取角色
    query_role_group_info_list({
      data: {
        ent_id: this.$store.state.ent_id,
        active: '1'
      }
    }).then(res => {
      if (res.code === '200') {
        for (const i in res.body.data) {
          if (res.body.data[i].role_id) {

          } else {
            // res.body.data[i].disabled=true
          }
          res.body.data[i].role_name = res.body.data[i].role_group_name
        }

        this.jiaoseOption = res.body.data
      }
    })
    query_shop_info_list({
      data: {
        ent_id: this.$store.state.ent_id
      }
    }).then(res => {
      if (res.code == 200) {
        this.shopOption = res.body.data.map((item) => {
          return {
            shop_name: item.shop_name,
            shop_id: item.shop_id
          }
        })
        this.$nextTick(() => {
          this.query_ent_img_shop_list()
        })
      } else {
        this.$message({
          message: '获取门店信息失败',
          type: 'error'
        })
      }
    })
  },
  components: {settingRules, integralRules, activityRules},
  methods: {
    GetRule() {
      query_member_rule({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        console.log(res.body.data);
        if (res.body.data) {
          this.form = res.body.data
          console.log(this.form.list_chan)
          this.form.use_amt = res.body.data.rule_point.use_amt
          this.form.use_point = res.body.data.rule_point.use_point
          this.form.consume_amt = res.body.data.rule_point.consume_amt
          this.form.consume_point = res.body.data.rule_point.consume_point
          if (this.form.list_level && this.form.list_level.length > 0) {
            for (const i in this.form.list_level) {
              this.form.list_level[i].SCLIST = this.form.list_level[i].prod_ids.split(',')
            }
          }

          for (const i in this.form.list_msg) {
            this.form.list_msg[i].role_id = this.form.list_msg[i].role_id.split(',')
          }

          for (const i in this.form.list_coupon) {
            this.form.list_coupon[i].shop_id = this.form.list_coupon[i].shop_id.split(',')
            this.form.list_coupon[i].timeDY = [this.form.list_coupon[i].time_start, this.form.list_coupon[i]
              .time_end
            ]

            console.log(this.form.list_coupon[i].date_valid)

            if (this.form.list_coupon[i].date_valid instanceof String) {
              this.form.list_coupon[i].date_valid = []
            } else {
              this.form.list_coupon[i].date_valid = [this.form.list_coupon[i].date_valid_start, this.form
                .list_coupon[i].date_valid_end
              ]
            }

            this.form.chan_scope = this.form.list_coupon[i].scope
            this.form.list_coupon[i].type_use_date = this.form.list_coupon[i].type_use_date.split(',')
          }
          // this.form.flag_index = Number(this.form.flag_index-1)
          console.log(this.form.flag_index)

          this.form = JSON.parse(JSON.stringify(this.form))
        }
      })
    },
    test(val) {
      console.log(val)
    },
    beforeAvatarUpload(file) {
      console.log('====================',file);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'bpm' ||
          testmsg === 'BPM'
      const isLt50M = file.size / 1024 / 1024 < 0.5
      if (!extension) {
        this.$message({
          message: '上传图片只能是jpg / png / bpm格式!',
          type: 'error'
        })
        return false // 必须加上return false; 才能阻止
      }
      console.log(isLt50M,file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'error'
        })
        return false
      }
      return extension || isLt50M
    },
    ...mapActions(['get_cat_list']),
    cur_shop_change() {
      this.query_ent_img_list()
    },
    // 获取是否已经设置过营销图的门店
    query_ent_img_shop_list() {
      const data = {
        ent_id: this.$store.state.ent_id
      }
      query_ent_img_shop_list({
        data
      }).then(res => {
        const tagres = res.body.data.map(x => x.shop_id)
        this.shopOption.forEach((item) => {
          // console.log(tagres,item,tagres.indexOf(item.shop_id) !== -1);
          if (tagres.indexOf(item.shop_id) !== -1) {
            item.status = '已设置'
          } else {
            item.status = '未设置'
          }
        })
        this.$set(this.shopOption, this.shopOption)
      })
    },
    // 获取默认展示数据
    query_ent_img_list() {
      const data = {
        ent_id: this.$store.state.ent_id,
        shop_id: this.cur_shop,
        img_type: null
      }
      query_ent_img_list({
        data
      }).then(res => {
        // console.log(res,'获得的图片数据');
        if (res.code === '200') {
          this.allImg = res.body.data
          const data = res.body.data
          this.MarketList = []
          this.HeaList = []
          this.EnvList = []
          data.forEach(item => {
            if (item.img_type === '1') {
              item.name = item.img_name,
              item.url = item.img_url
              this.MarketList.push(item)
            }
            if (item.img_type === '2') {
              item.name = item.img_name,
              item.url = item.img_url
              this.HeaList.push(item)
            }
            if (item.img_type === '3') {
              item.name = item.img_name,
              item.url = item.img_url
              this.EnvList.push(item)
            }
          })

          this.cantinglist = this.EnvList.length
          this.jiankanglist = this.HeaList.length
          this.yingxiaolist = this.MarketList.length
        }
      })
    },
    // 保存
    handlesave() {
      // console.log(this.filelist)
      const data = {
        ent_id: this.$store.state.ent_id,
        user_id: this.$store.state.user_id,
        shop_id: this.cur_shop,
        list_market: this.imglist,
        list_health: this.imglists,
        list_env: this.imglistss
      }
      console.log(data, '1233=============================')
      replace_ent_img({
        data
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          })

          this.MarketList = []
          this.HeaList = []
          this.EnvList = []
          this.shop = ''
          this.query_ent_img_list()
        }  else if (res.code == '1200' || res.code == '1100') {
          this.$message({
            message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
            type: 'error'
          })
        } else {
          this.$message({
            message: '上传失败',
            type: 'error'
          })
        }
      })
    },


// before-upload


    // 转化格式64  并上传图片 营销图
    handlechangeimg(file, filelist) {
      this.yingxiaolist = filelist.length
      this.imglist = []
      let file_list_index = ''

      const self = this
      filelist.forEach((item,index) => {

        let size = (item.raw.size /1024).toFixed(0)
        console.log(size );

        file_list_index = index

        if (size < 500) {

          if (item.raw) {
            const reader = new FileReader()
            reader.readAsDataURL(item.raw)
            let base64Str = ''
            new Promise((resolve, reject) => {
              reader.onload = function(e) {
                base64Str = e.target.result.substring(
                  e.target.result.indexOf(',') + 1,
                  e.target.result.length
                )
                resolve(base64Str)
              }
            }).then(res => {
              const objself = {
                img_id: String(item.uid),
                img_name: item.name,
                img_url: res, // 图片数据
                img_url_type: item.name.substring(item.name.lastIndexOf('.'), item.name.length),
                status_check: '0' // 上传的文件是0，原来就有的数据带的是自身的状态值
              }
              self.imglist.push(objself)
            })
          }
          this.MarketList = filelist

        }else{

          filelist.splice(file_list_index,1)
          this.EnvList =filelist

          this.$message({
            type: 'success',
            message: '图片大小请不超过500kB'
          })
        }


      })
    },
    // 健康证明
    handlechangeimg1(file, filelist) {
      this.jiankanglist = filelist.length
      this.imglists = []
      let file_list_index = ''

      const self = this
      filelist.forEach((item,index) => {
        console.log('健康证明--健康证明',item);
        let size = (item.raw.size /1024).toFixed(0)
        console.log(size );

        file_list_index = index

        if (size < 500) {
          
          if (item.raw) {
            const reader = new FileReader()
            reader.readAsDataURL(item.raw)
            let base64Str = ''
            new Promise((resolve, reject) => {
              reader.onload = function(e) {
                base64Str = e.target.result.substring(
                  e.target.result.indexOf(',') + 1,
                  e.target.result.length
                )
                resolve(base64Str)
              }
            }).then(res => {
              const objself = {
                img_id: String(item.uid),
                img_name: item.name,
                img_url: res, //	图片数据
                img_url_type: item.name.substring(item.name.lastIndexOf('.'), item.name.length),
                status_check: '0' // 上传的文件是0，原来就有的数据带的是自身的状态值
              }
              self.imglists.push(objself)
            })
          }
          this.HeaList = filelist


        }else{

          filelist.splice(file_list_index,1)
          this.EnvList =filelist

          this.$message({
            type: 'success',
            message: '图片大小请不超过500kB'
          })
        }

      })
    },
    // 餐厅环境
    handlechangeimg2(file, filelist) {
      this.cantinglist = filelist.length
      this.imglistss = []
      let file_list_index = ''

      const self = this
      filelist.forEach((item , index )=> {
        let size = (item.raw.size /1024).toFixed(0)
        console.log(size );
        file_list_index = index
        if (size < 500) {

        if (item.raw) {
          const reader = new FileReader()
          reader.readAsDataURL(item.raw)
          let base64Str = ''
          new Promise((resolve, reject) => {
            reader.onload = function(e) {
              base64Str = e.target.result.substring(
                e.target.result.indexOf(',') + 1,
                e.target.result.length
              )
              resolve(base64Str)
            }
          }).then(res => {
            const objself = {
              img_id: String(item.uid),
              img_name: item.name,
              img_url: res, //	图片数据
              img_url_type: item.name.substring(item.name.lastIndexOf('.'), item.name.length),
              status_check: '0' // 上传的文件是0，原来就有的数据带的是自身的状态值
            }
            self.imglistss.push(objself)
          })
        }
        this.EnvList = filelist

        }else{
          console.log('file_list_indexfile_list_index',file_list_index);

          filelist.splice(file_list_index,1)
          this.EnvList =filelist

          this.$message({
            type: 'success',
            message: '图片大小请不超过500kB'
          })
        }

      })
    },

    // 适用门店选择
    handleToplist() {
      // this.topSelList = this.shop

      // this.MarketList = []
      // this.HeaList = []
      // this.EnvList = []

      const arr = [
        [],
        [],
        []
      ]

      // 查询图片状态
      query_ent_img_list({
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.topSelList
          // img_type: '1' //图类型:1营销图，2健康证明，3就餐环境
        }
      }).then(res => {
        let imgobj = {}

        for (const index in res.body.data) {
          if (res.body.data[index].img_type == 1) {
            [
              arr[0].push(res.body.data[index].img_id)
            ]
          }
          if (res.body.data[index].img_type == 2) {
            [
              arr[1].push(res.body.data[index].img_id)
            ]
          }
          if (res.body.data[index].img_type == 3) {
            [
              arr[2].push(res.body.data[index].img_id)
            ]
          }
        }

        this.allImgIdList = arr

        res.body.data.forEach(item => {
          if (item.img_type == 1) {
            imgobj = {
              name: item.img_name,
              url: item.img_url
            }
            this.MarketList.push(imgobj)
            this.yingxiaolist = this.MarketList.length
          }
          if (item.img_type == 2) {
            imgobj = {
              name: item.img_name,
              url: item.img_url
            }
            this.HeaList.push(imgobj)
            this.jiankanglist = this.HeaList.length
          }
          if (item.img_type == 3) {
            imgobj = {
              name: item.img_name,
              url: item.img_url
            }
            this.EnvList.push(imgobj)
            this.cantinglist = this.EnvList.length
          }
        })
      })
    },
    handleChange() {

    },
    // 导航改变下标
    handlechange(val) {
      this.navindex = val
      // if (this.navindex == 0) {
      //   this.navindex = 1
      // } else {
      //   this.navindex = 0
      // }
    },
    // 删除 图片
    handleRemove(file, val) {
      const delID = file.url

      if (file.status == 'ready') {
        if (val == 1) {
          for (const i in this.MarketList) {
            console.log(file)

            console.log(this.MarketList)

            if (this.MarketList[i].uid == file.uid) {
              this.MarketList.splice(i, 1)
              // delete this.MarketList[i]
            }

            for (const k in this.imglist) {
              if (Number(this.imglist[k].img_id) == file.uid) {
                this.MarketList.splice(k, 1)
              }
            }
          }
        }
        if (val == 2) {
          for (const i in this.HeaList) {
            console.log(this.HeaList)
            if (this.HeaList[i].uid == file.uid) {
              this.HeaList.splice(i, 1)
            }
            for (const k in this.imglists) {
              if (Number(this.imglists[k].img_id) == file.uid) {
                this.HeaList.splice(k, 1)
              }
            }
          }
        }
        if (val == 3) {
          for (const i in this.EnvList) {
            console.log(this.EnvList)
            if (this.EnvList[i].uid == file.uid) {
              this.EnvList.splice(i, 1)
            }
            for (const k in this.imglistss) {
              if (Number(this.imglistss[k].img_id) == file.uid) {
                this.EnvList.splice(k, 1)
              }
            }
          }
        }
      } else {
        for (const i in this.allImg) {
          if (delID == this.allImg[i].img_url) {
            delete_ent_img({
              data: {
                ent_id: this.$store.state.ent_id,
                user_id: this.$store.state.user_id,
                shop_id: this.allImg[i].shop_id,
                img_type: this.allImg[i].img_type,
                img_id: this.allImg[i].img_id
                // this.MarketList[item]
              }
            }).then(res => {
              this.query_ent_img_list()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            })
          }
        }
      }

      // this.MarketList =  Array.from(this.MarketList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    formSure() {
      //       console.log(this.form.list_msg)
      // date_valid
      // console.log()

      for (const i in this.form.list_coupon) {
        for (const j in this.form.list_coupon) {
          if (i != j) {
            if (this.form.list_coupon[i].amt_condition == this.form.list_coupon[j].amt_condition) {
              this.$message({
                type: 'error',
                message: '优惠券金额重复!'
              })
              return
            }
          }
        }
      }

      const form2 = JSON.parse(JSON.stringify(this.form))

      this.form.ent_id = this.$store.state.ent_id
      const data = {
        ent_id: this.$store.state.ent_id
      }
      if (this.form.flag_coupon == '1') {
        for (const i in this.form.list_coupon) {
          this.form.list_coupon[i].coupon_id = Number(i) + 1
          this.form.list_coupon[i].coupon_id = String(this.form.list_coupon[i].coupon_id) + '' + this.$store.state
            .ent_id
          this.form.list_coupon[i].time_start = this.form.list_coupon[i].timeDY[0]
          this.form.list_coupon[i].time_end = this.form.list_coupon[i].timeDY[1]
          this.form.list_coupon[i].type_use_date = this.form.list_coupon[i].type_use_date.join(',')
          this.form.list_coupon[i].shop_id = this.form.list_coupon[i].shop_id.join(',')
          delete this.form.list_coupon[i].timeDY
        }
      }

      for (const i in this.form.list_chan) {
        this.form.list_chan[i].option_id = i + 1
      }

      // this.form.list_coupon=JSON.stringify(this.form.list_coupon)

      if (this.form.list_msg && this.form.list_msg.length > 0) {
        for (const i in this.form.list_msg) {
          this.form.list_msg[i].temp_id = i + 1
          // list_msg
          if (this.form.list_msg[i].role_id instanceof Array) {
            this.form.list_msg[i].role_id = this.form.list_msg[i].role_id.join(',')
          }
        }
      }

      if (this.form.list_level && this.form.list_level.length > 0) {
        for (const i in this.form.list_level) {
          this.form.list_level[i].level_id = i + 1
          if (this.form.list_level[i].SCLIST && this.form.list_level[i].SCLIST.length > 0) {
            this.form.list_level[i].prod_ids = this.form.list_level[i].SCLIST.join(',')
          }
        }
      }

      if (this.form.list_level && this.form.list_level.length) {
        for (const i in this.form.list_level) {
          for (const j in this.form.list_level) {
            if (i != j) {
              if (this.form.list_level[i].amt_topup === this.form.list_level[j].amt_topup) {
                this.$message({
                  type: 'error',
                  message: '会员规则重复!'
                })
                return
              }
            }
          }
        }
      }

      console.log('form2form2form2form2form2form2',form2)

      for (const i in this.form.list_coupon) {
        this.form.list_coupon[i].date_valid_start = this.form.list_coupon[i].date_valid[0]
        this.form.list_coupon[i].date_valid_end = this.form.list_coupon[i].date_valid[1]
      }

      // for (i = 0; i < this.form.list_coupon.length; i++) {
      //   for (j = i + 1; j < arr.length; j++) {
      //     if (arr[i].name === arr[j].name) {
      //       console.log("重名了！！");
      //     }
      //   }

      // 666666

      // this.form.flag_index = String(this.form.flag_index+1)
      console.log('this.form',this.form)

      insert_member_rule({
        data: this.form
      }).then(res => {
        if (res.code == '200') {
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
          this.form = form2

          this.$router.push('/MarketingCampaign')
        } else if (res.code == 500) {
          this.$message({
            type: 'error',
            message: '保存失败!'
          })
          this.form = form2
        } else if (res.code == '1200' || res.code == '1100') {
          this.$message({
            message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
            type: 'error'
          })
        }
      })
    },
    addNode() {
      this.form.list_chan.push({
        option_id: '',
        option_name: ''
      })
    },
    // 添加优惠
    handleadddis() {
      this.form.list_coupon.push({
        coupon_id: this.form.list_coupon.length + this.$store.state.ent_id,
        shop_id: this.topSelList, // 适用门店
        name: '',
        dixian: '',
        dis: '',
        edate: '',
        udate: '',
        time_start: '',
        time_end: '',
        amt_condition: '',
        range: '', // 范围
        give_total:'',// 发售预计总量
      })
    },
    handledeldis(i) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.coupon.splice(i, 1)
        this.form.list_coupon.splice(i, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    handleintegral() {
      this.form.list_level.push({
        rec: '',
        give: ''
      })
    },
    handledelint(i) {
      if (i != 0) {
        this.form.list_level.splice(i, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '不能再删了!'
        })
      }
    },
    handlesms() {
      // list_msg
      this.form.list_msg.push({
        temp_id: '',
        temp_name: '',
        text: '',
        scope: '',
        role_id: ''
      })
    },
    handledelsms(i) {
      if (i != 0) {
        this.form.list_msg.splice(i, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '不能再删了!'
        })
      }
    },
    // 删除样本div
    deleteNode(i) {
      if (i != 0) {
        this.form.list_chan.splice(i, 1) // 删除index为i,位置的数组元素
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '不能再删了!'
        })
      }
    }

  },
  mounted() {
    // 获取门店信息

  }
}
</script>

<style scoed lang="scss">
  .MarketingCampaign-box {
    .el-collapse-item__wrap {
      .el-collapse-item__content {
        div {
          div {
            display: flex;
          }
        }
      }
    }

    .save {
      width: 100%;
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: center;

      .el-button--primary {
        margin: 0px;
      }
    }

    .MarketingCampaign {
      .MarketingCampaign-form {
        .refill-box {
          input {
            width: 120px;
          }
        }
      }
    }

    .block {
      .el-input__prefix {
        right: 235px;
      }
    }
  }

  .Mar-nav {
    width: 100%;
    height: 80px;
    font-size: 15px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;

    p {
      width: 120px;
      height: 77px;
      text-align: center;
      line-height: 77px;
    }

    .psel {
      border-bottom: 3px solid red;
    }
  }

  .el-main {
    background: #f2f6fc;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
  }

  .MarketingCampaign {
    width: 100%;
    height: 100%;
    padding-bottom: 200px;
    color: #f6f6f3;
    background: #fefefe;
    border-radius: 10px;
    margin-top: 29px;

    .MarketingCampaign-title {
      padding: 23px 0 0 20px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 12px;

      >span {
        display: inline-block;
        width: 6px;
        height: 30px;
        background: linear-gradient(221deg, #fc4353, #ff5e41);
        border-radius: 3px;
        margin-right: 11px;
      }
    }

    .MarketingCampaign-form {
      padding-left: 80px;

      .Member-switch {
        padding-left: 17px;
        margin: 86px 0 43px 0;

        .Member-switch-span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          padding-right: 23px;
        }
      }

      .Member-radio {
        padding-left: 17px;

        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-bottom: 32px;
        }
      }

      .refill-box {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 10px;

        .del {
          margin-left: 30px;
          color: #ff404e;
        }

        input {
          width: 100px;
          height: 40px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          outline: none;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin: 0 11px;

          &:nth-of-type(4) {
            width: 150px;
          }
        }

        .refill-input {
          text-indent: 2em;
        }

        .memberInput {
          text-indent: 1em;
        }

        .integralInput {
          width: 60px;
          height: 40px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          text-align: center;
          outline: none;
          // color: #ff404e;
        }
      }

      .AddSolution {
        width: 90px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #ff404e;
        border-radius: 5px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ff404e;
        text-align: center;
        outline: none;
        background: #fff;
        margin: 24px 0 0 120px;
      }

      .coupon {
        margin: 0 0 0 300px;
      }

      .memberRadio {
        margin: 0 50px;
      }

      .rule-span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .ruleInput {
        width: 80px;
        height: 36px;
        border-radius: 5px;
        margin: 0 10px;
        outline: none;
      }

      .DiscountWay {
        display: flex;
        flex-wrap: wrap;
      }

      .discountCouponInput {
        width: 260px;
        height: 36px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        text-indent: 1em;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        outline: none;
      }

      .el-form-item {
        margin-bottom: 26px;
      }

      .CouponInput-box {
        input {
          outline: none;
          text-indent: 1em;
          width: 235px;
          height: 36px;
          border: 1px solid #cccccc;
          border-radius: 5px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .CouponInputOne {
          margin-left: 223px;
        }

        .CouponInput {
          margin-left: 110px;
        }

        .Many {
          padding-left: 10px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .formSureBox {
      margin: auto;
      width: 110px;

      .formSure {
        display: inline-block;
        width: 110px;
        height: 50px;
        background: #fc4353;
        border-radius: 10px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        outline: none;
        border: none;
        margin-top: 180px;
      }
    }

    .MarketingCampaign-p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      padding: 21px 0 30px 37px;
    }

    .MarketingCampaign-collapse {
      padding-left: 36px;

      .file-box {
        width: 515px;
        height: 218px;
      }

      .el-upload-list__item-actions {
        line-height: 0;

        span {
          display: block;
          position: absolute;

          &:first-of-type {
            right: 20px;
            top: 20px;
          }

          &:last-of-type {
            top: 100px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
      }

      .el-upload-list__item-delete {
        font-size: 25px;
        color: #fff;
      }

      .el-icon-picture-outline {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        font-size: 20px;
        color: #fc4353;
      }

      .el-collapse-item__header {
        color: #333;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }

  .div_vip {
    width: 800px;
    position: relative;

    img {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    span {
      width: 50px;
      color: #fc4353;
      position: absolute;
      right: -80px;
      top: 50px;
    }
  }

  .div_ceshi {
    display: block;
    margin-top: 40px;

    .el-textarea {
      .el-textarea__inner {
        width: 800px;
      }
    }

    .div_xaunz {
      margin-bottom: 40px;
      display: flex;

      span {
        color: #333333;
        font-size: 16px;
      }
    }

    .div_ceshi_box {
      &>div {
        margin-bottom: 30px;
      }
    }

    .div_ceshiS {
      margin: 0 0 30px 100px;

      span {
        color: #333;
        font-size: 16px;
        margin-right: 10px;

        &:nth-of-type(2) {
          color: #fc4353;
          margin-left: 20px;
        }
      }

      p {
        font-size: 16px;
        line-height: 31px;
        text-align: center;
        margin: 20px 200px;
        color: #333;
        display: block;
        width: 90px;
        height: 31px;
        border: 1px solid #fc4353;
        border-radius: 5px;
        color: #fc4353;
      }
    }
  }
</style>

<style>
  .MarketingCampaign-box .MarketingCampaign .MarketingCampaign-form .refill-box .el-cascader input {
    width: 200px;
  }

  /* .Selc .el-cascader  input{
  width: 300px  !important;
  height: 50px !important;
} */
</style>
