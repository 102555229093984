<template>
    <div class="coupon_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <!--
                // 
                // 优惠限制
                // 
            -->
            <div class="MarketingCampaign-title"><span></span>优惠限制</div>
            <el-form-item label="" :class="{'bgf9': index%2}" v-for="(item, index) in list" :key="'li'+ index">
                <div class="rules_row col_n">
                    <div class="rules_item">
                        <span class="text_desc tit">方案{{index+1}}</span>
                        <span class="con">
                            <el-select v-model="item.limitArr" multiple placeholder="请选择" style="width: 160px;" @change="changeLimit($event, index)" @remove-tag="changeLimit" :key="'sel'+index">
                                <el-option v-for="(child, indx) in changeList" :disabled="child.disabled" :key="indx" :label="child.label" :value="child.id"></el-option>
                            </el-select>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">单次最多使用</span>
                        <span class="con">
                            <el-input min="0" placeholder="请输入" v-model="item.kinds_num" style="width: 180px" @input="setInfo">
                                <template slot="append">张</template>
                            </el-input>
                        </span>
                        <i class="el-icon-delete del_btn" @click="delItem(index, 'limit')"> 删除</i>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem('limit')"> 新增优惠限制</i>
            </el-form-item>
            
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        init_list: {type: Array, default: ()=>[]},
    },
    data() {
        return {
            list: [],
            selectedLimit: [],
            limitArr: [],
            changeList: [
                {id: '1', label: '拼团'},
                {id: '2', label: '砍价'},
                {id: '3', label: '抽奖'},
                {id: '4', label: '大礼包'},
                {id: '5', label: '积分兑换'}
            ]
        }
    },
    mounted() {
        this.initForm()
    },
    methods: {
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        addItem() {
            this.list.push(this.formData())
        },
        initForm() { 
            this.list = [this.formData()]
        },
        getSelectedLimit() {
            this.changeList.map(item=>{
                item.disabled = false
            })
            this.selectedLimit = []
            this.list.map(item=>{
                let arr = item.limitArr
                this.selectedLimit = this.selectedLimit.concat(arr)
            })
            this.selectedLimit.map(item=>{
                this.changeList.map(child=>{
                    if(child.id == item){
                        child['disabled'] = true
                    }
                })
            })
        },
        setInfo() {
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }
                obj.plan_content = item.limitArr.join(',')
                list.push(obj)
            })
            this.getSelectedLimit()
            this.$emit('getList', list)
        },
        changeLimit(e, index) {
            this.setInfo()
        },
        formData() {
            return {
                plan_content: '',
                kinds_num: ''
            }
        }
    },
    watch: {
        init_list: function(v, o) {
            if(v && v.length > 0) {
                let arr = []
                v.map(item=>{
                    let obj = {}
                    for(let key in item) {
                        obj[key] = JSON.parse(JSON.stringify(item[key]))
                    }
                    obj['limitArr'] = item.plan_content? item.plan_content.split(','): []
                    arr.push(obj)
                })
                this.list = arr
            } else {
                this.initForm()
            }
            this.getSelectedLimit()
        }
    }
}
</script>
<style lang="scss" scoped>
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>