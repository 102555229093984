<template>
    <div class="coupon_box">
        <el-form ref="form" label-width="60px" class="form_info_box">
            <!--
                // 
                // 幸运大抽奖
                // 
            -->
            <div class="MarketingCampaign-title"><span></span>幸运大抽奖</div>
            <el-form-item label="">
                <slot name="lottery_before"></slot>
            </el-form-item>
            <el-form-item label="">
                <div class="rules_row col_1">
                    <div class="rules_item">
                        <span class="text_desc tit">使用日期</span>
                        <el-date-picker v-model="lotteryDateTime.useDatePicker" :picker-options="pickerOptions" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        <span class="text_desc tit">可用日期</span>
                        <el-select v-model="lotteryDateTime.date_type" multiple placeholder="请选择" dataName="日期选择" @change="setInfo">
                            <el-option v-for="item in dayList" :key="'date-'+item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>

                        <!-- <el-radio-group v-model="lotteryDateTime.use_date_type">
                            <el-radio :label="'1'">日期选择
                                <el-select v-model="lotteryDateTime.date_type" multiple :disabled="lotteryDateTime.use_date_type == '2'" placeholder="请选择" dataName="日期选择" @change="setInfo">
                                    <el-option v-for="item in dayList" :key="'date-'+item.id" :label="item.label" :value="item.id"></el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="'2'">日期区间
                                <el-date-picker v-model="lotteryDateTime.useDatePicker" :picker-options="pickerOptions" :disabled="lotteryDateTime.use_date_type == '1'" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setInfo"></el-date-picker>
                            </el-radio>
                        </el-radio-group> -->
                    </div>
                </div>
                <div class="rules_row col_1">
                    <div class="rules_item" style="display:flex; align-items: center;">
                        <span class="text_desc tit">使用时间</span>
                        <el-radio-group v-model="lotteryDateTime.use_time_type" @change="useTimeTypeChange($event)" style="display:flex; align-items: center;">
                            <el-radio :label="'1'">早午晚市</el-radio>
                            <el-checkbox-group v-model="lotteryDateTime.market_type" :disabled="lotteryDateTime.use_time_type == '2'" @change="setInfo" style="margin-right: 10px;">
                                <el-checkbox :label="child.id" v-for="child in marketList" :key="'l-daytype-'+child.id">{{child.label}}</el-checkbox>
                            </el-checkbox-group>
                            
                            <el-radio :label="'2'">时间区间
                                <el-time-picker is-range v-model="lotteryDateTime.useTimePicker" :disabled="lotteryDateTime.use_time_type == '1'" value-format="HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="changeTime"></el-time-picker>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="">
                <div class="rules_row col_n" style="display: flex; padding: 0;">
                    <div class="rules_item" style="display: flex;">
                        <span class="con">
                            <span class="text_desc tit">霸王餐抵用金额</span><el-input type="number" min="0" placeholder="请输入" v-model="lotteryDateTime.bw_amt" style="width: 175px" @input="setInfo"></el-input>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">中奖比例%</span>
                        <span class="con">
                            <el-select v-model="lotteryDateTime.bw_rate" placeholder="请选择" @change="setInfo('lottery', item, index, 'bawang')">
                                <el-option v-for="(item, index) in lotteryRate()" :key="index" :label="item" :value="item"></el-option>
                            </el-select>
                            <!-- <el-input type="number" min="0" placeholder="请输入" v-model="lotteryDateTime.bw_rate" style="width: 180px" @input="setInfo"></el-input> -->
                        </span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="" v-for="(item, index) in list" :key="'li'+ index">
                <div class="rules_row col_n" style="display: flex;">
                    <div class="rules_item" style="display: flex;">
                        <span class="text_desc tit">选择菜品</span>
                        <span class="con">
                            <el-cascader v-model="item.prod_id_arr" :options="$store.state.foodsShopList" :props="{multiple: false, label:'e_show_label', value:'e_show_id', children: 'prod_children'}" clearable @change="changeFoodsList($event, index)" :key="'l-m'+ index"></el-cascader>
                        </span>
                    </div>
                    <div class="rules_item">
                        <span class="text_desc tit">中奖比例%</span>
                        <span class="con">
                            <el-select v-model="item.rate" placeholder="请选择" @change="setInfo('lottery', item, index)">
                                <el-option v-for="(item, index) in lotteryRate()" :key="index" :label="item" :value="item"></el-option>
                            </el-select>
                            <!-- <el-input type="number" min="0" placeholder="请输入" v-model="item.rate" style="width: 180px" @input="setInfo('lottery', item, index)"></el-input> -->
                        </span>
                         <i class="el-icon-delete del_btn" @click="delItem(index)"> 删除</i>
                    </div>
                </div>
                <i v-if="index==list.length-1" class="el-icon-plus add_btn" @click="addItem('giftList')"> 新增抽奖菜品</i>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {insert_lottery_limit, query_lottery_limit} from '../../../../api/store'
export default {
    props:{
        init_list: {type: Array, default: ()=>[]},
        shop_id: {type: String | Number, default: ()=>''},
        marketList: {type: Array, default: ()=>[]},
        dayList: {type: Array, default: ()=>[]}
    },
    data() {
        return {
            list: [],
            pickerOptions: {
                disabledDate(time) {
                return !(time.getTime() > Date.now() - 24*60*60*1000);
                }
            },
            lotteryDateTime: {}
        }
    },
    components: {},
    created() {
        
    },
    mounted(){
        this.initForm()
    },
    methods: {
        changeTime(e) {},
        async initLotteryData() {
            let params = {data: {
                shop_id: this.$store.state.shop_id,
                ent_id: this.$store.state.ent_id,
                user_id: this.$store.state.user_id
            }}
            let res = await query_lottery_limit(params)
            if(res && res.code == 200) {
                let data = res.body.data
                console.log('dat抽奖a', data)
                this.$set(this.lotteryDateTime, 'useDatePicker', [data.use_date_start, data.use_date_end])
                this.$set(this.lotteryDateTime, 'use_time_type', data.use_time_type)
                this.$set(this.lotteryDateTime, 'useTimePicker', data.use_time_type=='1'? null: [data.use_time_start, data.use_time_end])
                this.$set(this.lotteryDateTime, 'market_type', data.market_type.split(','))
                this.$set(this.lotteryDateTime, 'date_type', data.date_type.split(','))
                this.$set(this.lotteryDateTime, 'bw_amt', data.bw_amt)
                this.$set(this.lotteryDateTime, 'bw_rate', data.bw_rate)
            } else if(res.code == 700) {
                this.lotteryDateTime = this.lotteryDateTimeData()
            }
            // console.log('this.lotteryDateTime', this.lotteryDateTime)
        },
        async submitLotteryForm() {
            let date_type = this.lotteryDateTime.date_type.join(',')
            let use_date_start = this.lotteryDateTime.useDatePicker[0]
            let use_date_end = this.lotteryDateTime.useDatePicker[1]
            let use_time_type = this.lotteryDateTime.use_time_type

            let market_type = use_time_type=='1'? this.lotteryDateTime.market_type.join(','): ''
            let use_time_start = use_time_type=='2' && this.lotteryDateTime.useTimePicker.length? this.lotteryDateTime.useTimePicker[0]: ''
            let use_time_end = use_time_type=='2' && this.lotteryDateTime.useTimePicker.length? this.lotteryDateTime.useTimePicker[1]: ''
            let params = {data: {
                shop_id: this.$store.state.shop_id,
                ent_id: this.$store.state.ent_id,
                user_id: this.$store.state.user_id,
                date_type,
                use_date_start,
                use_date_end,
                use_time_type,
                market_type,
                use_time_start,
                use_time_end,
                bw_amt: this.lotteryDateTime.bw_amt,
                bw_rate: this.lotteryDateTime.bw_rate
            }}
            console.log('params', params, JSON.stringify(params))
            await insert_lottery_limit(params)
        },
        initForm() { 
            this.list = [this.formData()]
        },
        delItem(index, key) {
            if(this.list.length - 1 <= 0) {
                this.$message({message: '不可删除，最少应该保留一条！', type: 'warning'});
                return
            }
            this.list.splice(index, 1)
            this.setInfo()
        },
        addItem() {
            if(this.list.length >= 6) {
                this.$message({message: '最多设置6例菜品，已达到最大菜品数量。', type: 'error'})
                return
            }
            this.list.push(this.formData())
        },
        useTimeTypeChange(v) {
            if(v=='1' && this.lotteryDateTime.market_type.length <= 0) {
                this.lotteryDateTime.market_type = ['1', '2', '3']
            }
        },
        setInfo(type, item, index, rateType) {
            if(type=='lottery') {
                let count = Number(this.lotteryDateTime.bw_rate)
                this.list.map(item=>{
                    count+=Number(item.rate)
                })
                if(count > 100 && !rateType) {
                    this.$message({message: '中奖比例总数不可超出100%', type: 'error'})
                    item.rate = ''
                } else if(count > 100 && rateType) {
                    this.$message({message: '中奖比例总数不可超出100%', type: 'error'})
                    this.lotteryDateTime.bw_rate = ''
                }
            }
            let list = []
            this.list.map(item=>{
                let obj = {}
                for(let key in item) {
                    obj[key] = item[key]
                }

                list.push(obj)
            })
            this.$emit('getList', list)
        },
        lotteryDateTimeData() {
            return {
                use_date_type: '1',
                bw_amt: '',
                bw_rate: '',
                date_type: [],
                use_date_start: '',
                use_date_end: '',
                use_time_type: '1',
                market_type: ['1', '2', '3'],
                use_time_start: '',
                use_time_end: '',
                useDatePicker: [],// 提交时需要删除
                useTimePicker: null,
            }
        },
        formData() {
            return {
                prod_id: '',
                rate: ''
            }
        },
        changeFoodsList(e, index) {
            // let idArr = []
            // e.map(item=>{
            //     idArr.push(item[1])
            // })
            this.list[index].prod_id = e[1]
            this.setInfo()
        },
        lotteryRate() {
            return [
                '0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100' 
            ]
        }
    },
    watch: {
        init_list: function(v, o) {
            // v = [{prod_id: "CP1625452633643xnul"}]
            if(v && v.length > 0) {
                this.initLotteryData()
                let arr = []
                v.map(item=>{
                    let obj = item
                    obj['prod_id_arr'] = []
                    // let foodsList = item.prod_id.split(',')
                    this.$store.state.foodsShopList.map(itemB=>{
                        itemB.prod_children.map(child=>{
                            if(child.prod_id == item.prod_id) {
                                obj.prod_id_arr=[itemB.id, item.prod_id]
                            }
                        })
                    })
                    arr.push(obj)
                })
                console.log('arr', arr)
                this.lotteryDateTime = this.lotteryDateTimeData()
                this.list = arr
            } else {
                this.initForm()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form_info_box{ padding: 0 40px; 
    .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; padding: 12px 20px; font-size: 14px; border-radius: 4px; box-sizing: border-box; cursor: pointer; margin-right: 15px; margin-bottom: 10px;}
    .text_desc,.show_text{ color: #333; margin: 0 10px; }
    .show_text{ margin: 0 3px; }
    .bgf9{ background: #f9f9f9; }
    .rules_row{ display: block; padding: 10px 0;
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        &.col_n{display: flex;
            .flex_1{ flex: 1; 
                .con{ margin-right: 25px; }
            }
        }
    }
    
}
</style>